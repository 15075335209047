@import "https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;600;700;800&amp;display=swap";

:root {
    --font-saira: "Saira", sans-serif;
    --white-color: #fff;
    --black-color: #000;
    --black-color2: #1D1D1D;
    --gray-color: #f7f7fd;
    --paragraph-color: #272727;
    --theme-color: #06D889;
    --body-color: #eaf1f5;
    --border-color: #232323;
    --black-soft-color: #7e7e7e
}

.tt-style-switch {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    font-size: 20px;
    color: var(--white-color)
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    outline: 0 solid
}

html,
body {
    height: 100%
}

body {
    font-size: 16px;
    font-family: var(--font-saira)
}

body.bg-dark-5 {
    background: #121212
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-saira);
    font-weight: 700;
    margin-bottom: 15px
}

img {
    max-width: 100%
}

a,
ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none
}

p {
    margin: 0;
    color: #272727;
    line-height: 1.8
}

a,
a:hover {
    text-decoration: none
}

input,
select,
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle
}

.sec-pad {
    padding: 120px 0
}

.sec-pad-top {
    padding-top: 120px
}

.sec-pad-bottom {
    padding-bottom: 120px
}

.sec-mar {
    margin: 120px 0
}

@media(max-width:991px) {
    .sec-mar {
        margin: 90px 0
    }
}

.sec-mar-top {
    margin-top: 120px
}

.sec-mar-bottom {
    margin-bottom: 120px
}

.pt-50 {
    padding-top: 50px
}

.pt-20 {
    padding-top: 20px
}

.pt-30 {
    padding-top: 30px
}

.mb-40 {
    margin-bottom: 40px !important
}

.mb-60 {
    margin-bottom: 60px
}

.mb-80 {
    margin-bottom: 80px
}

.mb-55 {
    margin-bottom: 55px
}

.pt-130 {
    padding-top: 130px
}

@media(max-width:991px) {
    .pt-130 {
        padding-top: 90px
    }
}

.mb-120 {
    margin-bottom: 120px
}

@media(max-width:991px) {
    .mb-120 {
        margin-bottom: 90px
    }
}

.pb-130 {
    padding-bottom: 130px
}

.mt-130 {
    margin-top: 130px
}

.mb-130 {
    margin-bottom: 130px
}

@media(max-width:991px) {
    .mb-130 {
        margin-bottom: 90px
    }
}

.section-title-3 {
    max-width: 720px;
    width: 100%
}

.section-title-3 h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 7px
}

.section-title-3 p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.section-title-4.two {
    max-width: 620px;
    width: 100%;
    margin: 0 auto 55px
}

.section-title-4.three {
    max-width: 620px;
    width: 100%
}

.section-title-4>span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 10px
}

.section-title-4 h2 {
    margin-bottom: 5px;
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:767px) {
    .section-title-4 h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .section-title-4 h2 {
        font-size: 30px
    }
}

.section-title-4 h2 span {
    color: var(--theme-color)
}

.section-title-4 p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0;
    padding-top: 20px
}

.section-title-4 .about-btn {
    padding-top: 35px
}

.section-title-5>span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #b5b5b5;
    display: inline-block;
    padding: 10px 40px;
    border: 1px solid rgba(6, 216, 137, .5);
    position: relative;
    margin-bottom: 10px
}

.section-title-5>span::after {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.section-title-5>span::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.section-title-5 h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 50px;
    letter-spacing: .01em;
    color: var(--white-color);
    margin-bottom: 0
}

.section-title-5 h2 span {
    font-weight: 400;
    color: #b5b5b5
}

@media(max-width:1199px) {
    .section-title-5 h2 {
        font-size: 40px
    }
}

@media(max-width:767px) {
    .section-title-5 h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .section-title-5 h2 {
        font-size: 30px
    }
}

.section-content {
    max-width: 336px;
    width: 100%
}

.section-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.section-title-6 {
    position: relative;
    height: 90px;
    display: flex;
    align-items: end;
    justify-content: center
}

@media(max-width:991px) {
    .section-title-6 {
        height: unset
    }
}

.section-title-6 h2 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 60px;
    letter-spacing: .01em;
    color: var(--white-color)
}

@media(max-width:576px) {
    .section-title-6 h2 {
        font-size: 40px
    }
}

.section-title-6 span {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 110px;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: rgba(6, 216, 137, .03);
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    line-height: 1
}

@media(max-width:991px) {
    .section-title-6 span {
        display: none;
        visibility: hidden
    }
}

.header-area2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    transition: all .8s ease-out 0s;
    border-bottom: 1px solid rgba(255, 255, 255, .1)
}

@media(max-width:1700px) {
    .header-area2 {
        padding: 0 7%
    }
}

@media(max-width:1500px) {
    .header-area2 {
        padding: 0 3%
    }
}

@media(max-width:1500px) {
    .header-area2.one {
        padding: 0 7%
    }
}

.header-area2.style-2 {
    padding: 0 5%
}

@media(max-width:1399px) {
    .header-area2.style-2 {
        padding: 0 3%
    }
}

.header-area2.style-2 .nav-right {
    gap: 40px
}

@media(max-width:1399px) {
    .header-area2.style-2 .nav-right {
        gap: 25px
    }
}

.header-area2.style-2 .header-btn a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .03em;
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    padding: 10px 35px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.header-area2.style-2 .header-btn a::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    background-color: var(--theme-color);
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.header-area2.style-2 .header-btn a:hover {
    color: var(--black-color2)
}

.header-area2.style-2 .header-btn a:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.header-area2.style-2 .header-logo {
    padding: 25px 0
}

@media(max-width:1199px) {
    .header-area2.style-2 .header-contact {
        display: none;
        visibility: hidden
    }
}

.header-area2.style-2 .header-contact span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .03em;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px
}

.header-area2.style-2 .header-contact h6 {
    margin-bottom: 0
}

.header-area2.style-2 .header-contact h6 a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .03em;
    color: var(--white-color);
    transition: .5s
}

.header-area2.style-2 .header-contact h6 a:hover {
    color: var(--theme-color)
}

.header-area2.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    box-sizing: border-box;
    background-color: #08090b;
    z-index: 99
}

@keyframes smooth-header {
    0% {
        transform: translateY(-30px)
    }

    100% {
        transform: translateY(0px)
    }
}

.header-area2 .header-logo {
    padding: 15px 0
}

.header-area2 .menu-close-btn i {
    color: var(--white)
}

.header-area2 .mobile-menu-form .hotline,
.header-area2 .mobile-menu-form .email {
    display: flex;
    align-items: center
}

.header-area2 .mobile-menu-form .hotline .hotline-icon svg,
.header-area2 .mobile-menu-form .hotline .email-icon svg,
.header-area2 .mobile-menu-form .email .hotline-icon svg,
.header-area2 .mobile-menu-form .email .email-icon svg {
    fill: var(--theme-color)
}

.header-area2 .mobile-menu-form .hotline .hotline-info,
.header-area2 .mobile-menu-form .hotline .email-info,
.header-area2 .mobile-menu-form .email .hotline-info,
.header-area2 .mobile-menu-form .email .email-info {
    margin-left: 12px
}

.header-area2 .mobile-menu-form .hotline .hotline-info span,
.header-area2 .mobile-menu-form .hotline .email-info span,
.header-area2 .mobile-menu-form .email .hotline-info span,
.header-area2 .mobile-menu-form .email .email-info span {
    font-size: 12px;
    font-weight: 400;
    font-family: var(--font-saira);
    color: var(--theme-color)
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6,
.header-area2 .mobile-menu-form .hotline .email-info h6,
.header-area2 .mobile-menu-form .email .hotline-info h6,
.header-area2 .mobile-menu-form .email .email-info h6 {
    margin-bottom: 0
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6 a,
.header-area2 .mobile-menu-form .hotline .email-info h6 a,
.header-area2 .mobile-menu-form .email .hotline-info h6 a,
.header-area2 .mobile-menu-form .email .email-info h6 a {
    font-size: 15px;
    font-weight: 400;
    font-family: var(--font-saira);
    color: var(--white-color);
    transition: .35s
}

.header-area2 .mobile-menu-form .hotline .hotline-info h6 a:hover,
.header-area2 .mobile-menu-form .hotline .email-info h6 a:hover,
.header-area2 .mobile-menu-form .email .hotline-info h6 a:hover,
.header-area2 .mobile-menu-form .email .email-info h6 a:hover {
    color: var(--theme-color)
}

.header-area2 .header-btn5 {
    margin-top: 50px;
    width: 100%
}

.header-area2 .header-btn5 a {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 11px 26px
}

.header-area2 .nav-right {
    gap: 30px
}

@media(max-width:991px) {
    .header-area2 .nav-right {
        gap: 0
    }
}

.header-area2 .nav-right .header-btn .primary-btn3 {
    padding: 10px 26px
}

.header-area2 .nav-right .sidebar-btn2 {
    height: 35px;
    width: 34px;
    border-radius: 56px;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .35s
}

.header-area2 .nav-right .sidebar-btn2:hover {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.header-area2 .nav-right .mobile-menu-btn {
    display: none;
    visibility: hidden
}

@media(max-width:991px) {
    .header-area2 .nav-right .mobile-menu-btn {
        display: flex;
        margin-left: 50px;
        flex-direction: column;
        align-items: end;
        visibility: visible;
        justify-content: center;
        position: relative
    }

    .header-area2 .nav-right .mobile-menu-btn span {
        height: 3px;
        width: 25px;
        background-color: var(--white-color);
        display: flex;
        transition: transform .5s ease-in;
        position: absolute;
        top: 0
    }

    .header-area2 .nav-right .mobile-menu-btn span::before {
        transition-duration: .5s;
        position: absolute;
        width: 35px;
        height: 3px;
        background-color: var(--white-color);
        content: "";
        top: -10px;
        right: 0
    }

    .header-area2 .nav-right .mobile-menu-btn span::after {
        transition-duration: .5s;
        position: absolute;
        width: 15px;
        height: 3px;
        right: 0;
        background-color: var(--white-color);
        content: "";
        top: 10px
    }

    .header-area2 .nav-right .mobile-menu-btn.active span {
        background-color: transparent
    }

    .header-area2 .nav-right .mobile-menu-btn.active span::before {
        transform: rotateZ(45deg) translate(8px, 6px);
        width: 35px
    }

    .header-area2 .nav-right .mobile-menu-btn.active span::after {
        transform: rotateZ(-45deg) translate(8px, -6px);
        width: 35px
    }
}

.header-area2 .main-menu {
    display: inline-block
}

.header-area2 .main-menu .mobile-menu-logo {
    display: none
}

.header-area2 .main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.header-area2 .main-menu ul>li {
    display: inline-block;
    position: relative;
    padding: 0 8px
}

.header-area2 .main-menu ul>li a {
    color: var(--white-color);
    display: block;
    text-transform: capitalize;
    padding: 32px 20px;
    position: relative;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 14px;
    transition: all .5s ease-out 0s;
    position: relative
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li a {
        padding: 32px 9px
    }
}

.header-area2 .main-menu ul>li i {
    font-size: 20px;
    text-align: center;
    color: var(--white-color);
    font-style: normal;
    position: absolute;
    right: -5px;
    top: 35px;
    z-index: 999;
    cursor: pointer;
    display: none;
    transition: all .5s ease-out 0s;
    opacity: 0
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li i {
        opacity: 1
    }
}

.header-area2 .main-menu ul>li i.active {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li i.active::before {
    content: "\f2ea"
}

.header-area2 .main-menu ul>li ul.sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    display: none;
    min-width: 215px;
    background: var(--white-color);
    box-shadow: 0 30px 80px rgba(8, 0, 42, .08);
    text-align: left
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li ul.sub-menu {
        box-shadow: none
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li {
    padding: 0;
    display: block;
    position: relative
}

.header-area2 .main-menu ul>li ul.sub-menu>li i {
    position: absolute;
    top: 10px;
    right: 6px;
    display: block;
    color: var(--black-color2);
    font-size: 20px
}

.header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon {
    color: var(--black-color2);
    opacity: 1;
    top: 13px;
    font-size: 13px;
    right: 15px
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon {
        right: 0;
        color: var(--white-color);
        top: 8px;
        font-size: 20px
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon2 {
    color: var(--black-color2);
    opacity: 1;
    top: 14px;
    right: 10px;
    font-size: 13px
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .dropdown-icon2::before {
        content: "\f4fe"
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li a {
    display: block;
    padding: 14px 20px;
    color: var(--text-color1);
    font-weight: 500;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 1;
    transition: all .4s ease-out 0s;
    position: relative;
    border-bottom: 1px solid #ddd
}

.header-area2 .main-menu ul>li ul.sub-menu>li a::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(90deg, #fff, #06D889, #fff);
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: left;
    transition: .4s ease-in;
    z-index: 1
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li a {
        border-color: #525252
    }

    .header-area2 .main-menu ul>li ul.sub-menu>li a::before {
        background: linear-gradient(90deg, #525252, #06D889, #525252)
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li a:hover {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li a:hover::before {
    transform: scale(1)
}

.header-area2 .main-menu ul>li ul.sub-menu>li a.active {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li a.active::before {
    transform: scale(1)
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child a {
    border-bottom: none
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child a::before {
    display: none
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover>a {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover .dropdown-icon {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li:hover .dropdown-icon2 {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active>a {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active .dropdown-icon {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li.active .dropdown-icon2 {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu {
    left: 100%;
    position: absolute;
    max-width: 230px;
    min-width: 215px;
    background: var(--white-color);
    box-shadow: 0 30px 80px rgba(8, 0, 42, .08);
    top: 0
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu::before {
    display: none;
    visibility: hidden
}

@media only screen and (max-width:991px) {
    .header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu {
        margin-left: 10px;
        position: unset;
        max-width: 230px;
        min-width: 215px;
        background: 0 0;
        top: 0
    }
}

.header-area2 .main-menu ul>li ul.sub-menu>li .sub-menu>li i {
    display: block
}

.header-area2 .main-menu ul>li ul.sub-menu>li:last-child {
    border-bottom: none
}

.header-area2 .main-menu ul>li.menu-item-has-children::after {
    content: "\f282";
    font-family: bootstrap-icons;
    font-weight: 0;
    position: absolute;
    top: 35px;
    right: 12px;
    font-size: 11px;
    color: var(--white-color);
    transition: all .55s ease-in-out
}

@media(max-width:1199px) {
    .header-area2 .main-menu ul>li.menu-item-has-children::after {
        right: 2px
    }
}

@media(max-width:991px) {
    .header-area2 .main-menu ul>li.menu-item-has-children::after {
        display: none;
        visibility: hidden
    }
}

@media(min-width:1200px) {
    .header-area2 .main-menu ul>li:hover>ul.sub-menu {
        display: block;
        animation: fade-up .45s linear
    }
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(10px);
        visibility: hidden
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible
    }
}

.header-area2 .main-menu ul>li:hover>a {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li:hover::after {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li:hover .dropdown-icon2 {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li.active>a {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li.active::after {
    color: var(--theme-color)
}

.header-area2 .main-menu ul>li.active .dropdown-icon2 {
    color: var(--theme-color)
}

.header-area2 .main-menu ul li.menu-item-has-children>i {
    display: block
}

@media only screen and (max-width:991px) {
    .header-area2 .mobile-logo-area {
        justify-content: center !important
    }

    .header-area2 .dropdown-icon {
        color: var(--white-color)
    }

    .header-area2 .main-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 280px;
        padding: 30px 20px !important;
        z-index: 99999;
        height: 100%;
        overflow: auto;
        background: #0a1019;
        transform: translateX(-100%);
        transition: transform .3s ease-in;
        box-shadow: 0 2px 20px rgba(0, 0, 0, .03)
    }

    .header-area2 .main-menu.show-menu {
        transform: translateX(0)
    }

    .header-area2 .main-menu .mobile-menu-logo {
        text-align: left;
        padding-top: 20px;
        display: block;
        padding-bottom: 8px
    }

    .header-area2 .main-menu .menu-list {
        padding-top: 50px;
        padding-bottom: 30px
    }

    .header-area2 .main-menu ul {
        float: none;
        text-align: left;
        padding: 5px 0 20px 0
    }

    .header-area2 .main-menu ul li {
        display: block;
        position: relative;
        padding: 0 5px
    }

    .header-area2 .main-menu ul li i {
        display: block
    }

    .header-area2 .main-menu ul li a {
        padding: 10px 0;
        display: block;
        font-size: 1rem
    }

    .header-area2 .main-menu ul li ul.sub-menu {
        position: static;
        min-width: 200px;
        background: 0 0;
        border: none;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        transform: none;
        transition: none;
        display: none;
        margin-top: 0 !important;
        transform: translateY(0px);
        margin-left: 10px
    }

    .header-area2 .main-menu ul li ul.sub-menu>li {
        border-bottom: 1px solid transparent
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a {
        color: var(--white-color);
        font-size: 14px;
        padding: 12px 0
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a:hover {
        color: var(--theme-color);
        margin-left: 10px
    }

    .header-area2 .main-menu ul li ul.sub-menu>li a.active {
        color: var(--theme-color)
    }

    .header-area2 .main-menu ul li ul.sub-menu>li i {
        color: var(--theme-color);
        right: -13px
    }

    .header-area2 .main-menu ul li .bi {
        top: 8px;
        font-size: 20px;
        color: var(--white-color)
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two {
        padding: 0 3%
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two .main-menu ul>li a {
        padding: 32px 14px
    }
}

@media(max-width:1399px) {
    .header-area2.two .main-menu ul>li a {
        padding: 32px 8px
    }
}

@media(max-width:991px) {
    .header-area2.two .main-menu ul>li a {
        padding: 10px 0
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .header-area2.two .main-menu ul>li.menu-item-has-children::after {
        right: 6px
    }
}

@media(max-width:1399px) {
    .header-area2.two .main-menu ul>li.menu-item-has-children::after {
        right: 1px
    }
}

.primary-btn3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .03em;
    color: var(--black-color2);
    border-radius: 5px;
    background-color: var(--theme-color);
    padding: 14px 26px;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.primary-btn3::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    border-radius: 2px;
    background-color: #0a1019;
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.primary-btn3:hover {
    color: var(--white-color)
}

.primary-btn3:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.primary-btn4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .03em;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 13px;
    transition: .5s
}

.primary-btn4 span {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.primary-btn4 span svg {
    stroke: var(--white-color);
    transition: .5s
}

.primary-btn4:hover {
    color: var(--theme-color)
}

.primary-btn4:hover span {
    border-color: var(--theme-color)
}

.primary-btn4:hover span svg {
    stroke: var(--theme-color);
    transform: rotate(45deg);
    animation: opcsc2 1s infinite alternate
}

@keyframes opcsc2 {
    to {
        transform: rotate(45deg), scale(1)
    }

    from {
        transform: rotate(45deg), scale(1.3)
    }
}

.primary-btn5 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    display: flex;
    align-items: center;
    transition: .5s
}

.primary-btn5>svg {
    fill: var(--theme-color)
}

.primary-btn5 .content {
    margin-left: -40px;
    display: flex;
    align-items: center;
    gap: 12px;
    transition: .5s
}

.primary-btn5 .content svg {
    stroke: var(--white-color);
    transition: .5s
}

.primary-btn5:hover {
    color: var(--white-color)
}

.primary-btn5:hover .content {
    color: var(--white)
}

.primary-btn5:hover .content svg {
    transform: rotate(45deg)
}

.primary-btn6 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    display: inline-block;
    letter-spacing: .03em;
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    transform: rotate(-90deg);
    white-space: nowrap;
    padding: 11px 35px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.primary-btn6::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    border-radius: 2px;
    background-color: var(--theme-color);
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.primary-btn6:hover {
    color: var(--black-color2)
}

.primary-btn6:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.primary-btn7 {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    display: flex;
    align-items: center;
    transition: .5s
}

.primary-btn7 .circle2 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.primary-btn7 .circle2 svg {
    stroke: var(--white-color);
    margin-left: -5px;
    transition: .5s
}

.primary-btn7 .text {
    margin-left: -18px;
    transition: .5s
}

.primary-btn7:hover {
    color: var(--theme-color)
}

.primary-btn7:hover .circle2 {
    border-color: var(--theme-color)
}

.primary-btn7:hover .circle2 svg {
    stroke: var(--theme-color);
    transform: rotate(45deg)
}

.primary-btn7:hover .text {
    margin-left: 15px
}

.primary-btn8 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #e5e5e5;
    display: inline-block;
    gap: 7px;
    position: relative;
    z-index: 1;
    padding: 10px 15px;
    white-space: nowrap;
    transition: .5s
}

.primary-btn8::after {
    content: "";
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleX(.3);
    transform-origin: left;
    transition: .5s
}

.primary-btn8 svg {
    stroke: var(--white-color);
    transform: rotate(45deg);
    margin-left: 7px
}

.primary-btn8:hover {
    color: var(--white-color)
}

.primary-btn8:hover::after {
    background-color: rgba(117, 218, 180, .2);
    transform: scaleX(1)
}

.primary-btn9 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 8px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--theme-color)
}

.primary-btn9 span {
    text-decoration: underline
}

.primary-btn9 svg {
    stroke: var(--theme-color);
    transition: .5s
}

.primary-btn9:hover {
    color: var(--white-color)
}

.primary-btn9:hover span {
    color: var(--white-color)
}

.primary-btn9:hover svg {
    stroke: var(--white-color)
}

.swiper-pagination1 {
    position: absolute;
    left: 19px !important;
    top: 50%;
    width: unset !important;
    bottom: unset !important;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 40px;
    z-index: 99
}

@media(max-width:767px) {
    .swiper-pagination1 {
        display: none;
        visibility: hidden
    }
}

.swiper-pagination1 .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .5);
    opacity: 1;
    position: relative
}

.swiper-pagination1 .swiper-pagination-bullet::after {
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.swiper-pagination1 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.swiper-pagination1 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.swiper-pagination1.two {
    width: unset !important;
    left: unset !important;
    right: 60px;
    transform: unset;
    top: 125px
}

.header-sidebar {
    max-width: 420px;
    background: var(--black-color2);
    padding: 60px 25px 0;
    position: fixed;
    min-height: 100vh;
    height: 100%;
    right: 0;
    z-index: 999;
    top: auto;
    overflow-y: scroll;
    border-left: 1px solid rgba(255, 255, 255, .3);
    transition: all .6s ease 0s;
    transform: translateX(100%)
}

@media(max-width:576px) {
    .header-sidebar {
        max-width: 320px
    }
}

.header-sidebar::-webkit-scrollbar {
    width: 1px
}

.header-sidebar.slide {
    transform: translate(0)
}

.header-sidebar .siderbar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px
}

.header-sidebar .siderbar-top .close-btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.header-sidebar .siderbar-top .close-btn i {
    font-size: 16px;
    color: #ddd
}

.header-sidebar .sidebar-content {
    padding: 30px 0
}

.header-sidebar .sidebar-content p {
    font-size: 18px;
    font-family: var(--font-saira);
    margin-bottom: 0;
    color: var(--white-color)
}

.header-sidebar .address-card {
    position: relative;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden
}

.header-sidebar .address-card .content {
    background: var(--black-color2);
    box-shadow: 3px 4px 30px rgba(136, 136, 136, .1);
    padding: 30px 0;
    z-index: 2;
    position: relative;
    transition: all .5s ease
}

.header-sidebar .address-card .content .header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px
}

.header-sidebar .address-card .content .header h3 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--white-color);
    margin-bottom: 0;
    transition: all .5s ease
}

.header-sidebar .address-card .content .header .location {
    border: 1px solid var(--theme-color);
    width: 2.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    background-color: transparent
}

.header-sidebar .address-card .content .header .location svg {
    fill: var(--theme-color);
    transition: all .5s ease
}

.header-sidebar .address-card .address-list {
    margin: 0;
    padding: 0
}

.header-sidebar .address-card .address-list li {
    font-size: 1rem;
    color: var(--white-color);
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: 8px;
    transition: all .5s ease
}

.header-sidebar .address-card .address-list li span {
    font-size: 16px;
    font-weight: 600;
    color: var(--white-color);
    transition: all .5s ease;
    margin-right: 10px
}

.header-sidebar .address-card .address-list li a {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    transition: all .5s ease
}

.header-sidebar .address-card .address-list li a:hover {
    color: var(--theme-color)
}

.header-sidebar .informations .single-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 15px
}

.header-sidebar .informations .single-info .icon {
    height: 2.8rem;
    min-width: 2.8rem;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center
}

.header-sidebar .informations .single-info .icon i {
    font-size: 20px
}

.header-sidebar .informations .single-info .info a {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    display: block;
    transition: all .5s ease
}

.header-sidebar .informations .single-info .info a:hover {
    color: var(--theme-color)
}

.header-sidebar .informations .single-info .info p {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    display: block;
    transition: all .5s ease
}

.header-sidebar .follow-area {
    padding: 30px 0
}

.header-sidebar .follow-area h5 {
    font-size: 22px;
    font-weight: 700;
    color: var(--white-color);
    position: relative;
    margin-bottom: 0
}

.header-sidebar .follow-area .para {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    font-family: poppins, sans-serif;
    line-height: 28px;
    margin-bottom: 10px
}

.header-sidebar .follow-area .blog-widget-body .follow-list {
    margin: 0;
    padding: 10px 0 0
}

.header-sidebar .follow-area .blog-widget-body .follow-list li a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background: 0 0;
    border-radius: 3px;
    font-size: 18px;
    color: var(--white-color);
    transition: all .35s ease-in-out
}

.header-sidebar .follow-area .blog-widget-body .follow-list li a:hover {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.banner-area3 {
    background-image: url(../img/home-3/home3-banner-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    z-index: 1
}

.banner-area3::before {
    content: "";
    background-color: rgba(255, 255, 255, .1);
    height: 100%;
    width: 1px;
    position: absolute;
    left: 60px;
    top: 0
}

@media(max-width:991px) {
    .banner-area3::before {
        display: none;
        visibility: hidden
    }
}

.banner-area3::after {
    content: "";
    background-color: rgba(255, 255, 255, .1);
    height: 100%;
    width: 1px;
    position: absolute;
    right: 60px;
    top: 0
}

@media(max-width:991px) {
    .banner-area3::after {
        display: none;
        visibility: hidden
    }
}

.banner-area3 .scroll-down {
    transform: rotate(90deg);
    position: absolute;
    right: 40px;
    bottom: 90px;
    z-index: 11
}

@media(max-width:767px) {
    .banner-area3 .scroll-down {
        display: none;
        visibility: hidden
    }
}

.banner-area3 .scroll-down a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px
}

.banner-area3 .scroll-down span {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    transform: rotate(45deg)
}

.banner-area3 .scroll-down span svg {
    stroke: var(--theme-color);
    transition: .5s
}

.banner-area3 .banner-vector {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1
}

.banner-area3 .social-area {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11
}

@media(max-width:576px) {
    .banner-area3 .social-area {
        display: none;
        visibility: hidden
    }
}

.banner-area3 .social-area ul {
    margin: 0;
    padding: 0;
    gap: 60px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.banner-area3 .social-area ul li {
    position: relative
}

.banner-area3 .social-area ul li::after {
    content: "";
    height: 2.5px;
    width: 2.5px;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translateX(-50%)
}

.banner-area3 .social-area ul li a {
    font-style: 24px;
    color: var(--white-color);
    transition: .4s
}

.banner-area3 .social-area ul li a:hover {
    color: var(--theme-color)
}

.banner-area3 .banner-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 215px 0 130px 180px;
    position: relative
}

@media(max-width:1750px) {
    .banner-area3 .banner-wrapper {
        padding: 215px 0 130px 120px
    }
}

@media(max-width:1600px) {
    .banner-area3 .banner-wrapper {
        padding: 215px 120px 130px
    }
}

@media(max-width:1400px) {
    .banner-area3 .banner-wrapper {
        padding: 215px 90px 130px
    }
}

@media(max-width:767px) {
    .banner-area3 .banner-wrapper {
        padding: 180px 20px 115px
    }
}

.banner-area3 .banner-wrapper .banner-content {
    max-width: 635px;
    width: 100%;
    z-index: 1
}

@media(max-width:1600px) {
    .banner-area3 .banner-wrapper .banner-content {
        max-width: 535px
    }
}

@media(max-width:1400px) {
    .banner-area3 .banner-wrapper .banner-content {
        max-width: 455px
    }
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-content {
        max-width: 455px
    }
}

.banner-area3 .banner-wrapper .banner-content h1 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 75px;
    line-height: 1.32;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 25px
}

@media(max-width:1600px) {
    .banner-area3 .banner-wrapper .banner-content h1 {
        font-size: 55px
    }
}

@media(max-width:1400px) {
    .banner-area3 .banner-wrapper .banner-content h1 {
        font-size: 50px
    }
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-content h1 {
        font-size: 48px
    }
}

@media(max-width:576px) {
    .banner-area3 .banner-wrapper .banner-content h1 {
        font-size: 35px
    }
}

.banner-area3 .banner-wrapper .banner-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #e4e4e4;
    margin-bottom: 50px;
    margin-right: 140px
}

@media(max-width:1600px) {
    .banner-area3 .banner-wrapper .banner-content p {
        margin-right: 80px
    }
}

@media(max-width:1400px) {
    .banner-area3 .banner-wrapper .banner-content p {
        margin-right: 50px
    }
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-content p {
        margin-right: 20px
    }
}

.banner-area3 .banner-wrapper .banner-content .banner-btn-group {
    display: flex;
    align-items: center;
    gap: 50px
}

@media(max-width:1400px) {
    .banner-area3 .banner-wrapper .banner-content .banner-btn-group {
        gap: 35px
    }
}

@media(max-width:576px) {
    .banner-area3 .banner-wrapper .banner-content .banner-btn-group {
        font-size: 15px;
        flex-wrap: wrap
    }
}

.banner-area3 .banner-wrapper .banner-image {
    border-radius: 50%;
    position: relative;
    margin-right: 80px;
    max-width: 624px;
    width: 100%
}

@media(max-width:1750px) {
    .banner-area3 .banner-wrapper .banner-image {
        margin-right: 50px
    }
}

@media(max-width:1650px) {
    .banner-area3 .banner-wrapper .banner-image {
        margin-right: 50px;
        max-width: 524px
    }
}

@media(max-width:1500px) {
    .banner-area3 .banner-wrapper .banner-image {
        margin-right: 30px;
        max-width: 424px
    }
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-image {
        max-width: 350px
    }
}

@media(max-width:991px) {
    .banner-area3 .banner-wrapper .banner-image {
        display: none;
        visibility: hidden
    }
}

.banner-area3 .banner-wrapper .banner-image::after {
    content: "";
    background-color: rgba(6, 216, 137, .25);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.banner-area3 .banner-wrapper .banner-image img {
    border-radius: 50%
}

.banner-area3 .banner-wrapper .banner-video-area {
    position: relative;
    height: 100%
}

.banner-area3 .banner-wrapper .banner-video-area .video-area {
    height: 175px;
    width: 175px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -310px
}

@media(max-width:1600px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area {
        top: -255px
    }
}

@media(max-width:1500px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area {
        left: -10px;
        top: -205px
    }
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area {
        height: 120px;
        width: 120px;
        left: -50px;
        top: -185px
    }
}

@media(max-width:991px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area {
        display: none;
        visibility: hidden
    }
}

.banner-area3 .banner-wrapper .banner-video-area .video-area::after {
    content: "";
    background: rgba(29, 29, 29, .7);
    border: 1px solid rgba(255, 255, 255, .2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1
}

.banner-area3 .banner-wrapper .banner-video-area .video-area::before {
    content: "";
    border: 1px solid rgba(255, 255, 255, .07);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    animation: opcsc2 4s infinite alternate
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area::before {
        height: 140px;
        width: 140px
    }
}

@keyframes opcsc2 {
    to {
        border-color: rgba(255, 255, 255, .07)
    }

    from {
        border-color: rgba(255, 255, 255, .2)
    }
}

.banner-area3 .banner-wrapper .banner-video-area .video-area .video-popup {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.banner-area3 .banner-wrapper .banner-video-area .video-area .video-popup svg {
    fill: var(--white-color);
    animation: opcsc 1s infinite alternate
}

.banner-area3 .banner-wrapper .banner-video-area .video-area .video-img {
    height: 175px;
    width: 175px;
    border-radius: 50%;
    position: relative
}

@media(max-width:1200px) {
    .banner-area3 .banner-wrapper .banner-video-area .video-area .video-img {
        height: 120px;
        width: 120px
    }
}

@keyframes opcsc {
    to {
        transform: scale(1)
    }

    from {
        transform: scale(1.3)
    }
}

.mfp-bg {
    background: 0 0
}

.mfp-content {
    animation: fade-out .35s linear
}

@keyframes fade-out {
    0% {
        opacity: 0;
        transform: scale(0);
        visibility: hidden;
        border-radius: 50%;
        overflow: hidden
    }

    100% {
        opacity: 1;
        transform: scale(1);
        visibility: visible;
        border-radius: 0%
    }
}

.banner-area4 {
    background-image: url(../img/home-4/home4-banner-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    padding: 255px 200px 128px;
    z-index: 1
}

@media(max-width:1800px) {
    .banner-area4 {
        padding: 240px 150px 128px
    }
}

@media(max-width:1700px) {
    .banner-area4 {
        padding: 240px 120px 128px
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area4 {
        padding: 240px 100px 70px
    }
}

@media(max-width:1399px) {
    .banner-area4 {
        padding: 240px 80px 70px
    }
}

@media(max-width:1199px) {
    .banner-area4 {
        padding: 240px 80px 70px 25px
    }
}

@media(max-width:991px) {
    .banner-area4 {
        padding: 220px 20px 70px
    }
}

@media(max-width:576px) {
    .banner-area4 {
        padding: 200px 0 70px
    }
}

.banner-area4 .banner-vector {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: -1
}

.banner-area4 .banner-content {
    max-width: 735px;
    width: 100%
}

.banner-area4 .banner-content h1 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 70px;
    line-height: 1.3;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 15px
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area4 .banner-content h1 {
        font-size: 60px
    }
}

@media(max-width:1399px) {
    .banner-area4 .banner-content h1 {
        font-size: 55px
    }
}

@media(max-width:1199px) {
    .banner-area4 .banner-content h1 {
        font-size: 50px
    }
}

@media(max-width:576px) {
    .banner-area4 .banner-content h1 {
        font-size: 38px
    }
}

.banner-area4 .banner-content h1 a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--theme-color)
}

.banner-area4 .banner-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #e4e4e4;
    margin-bottom: 45px;
    margin-right: 80px
}

@media(max-width:576px) {
    .banner-area4 .banner-content p {
        margin-right: 0
    }
}

.banner-area4 .banner-img {
    position: relative;
    display: inline-block
}

@media(max-width:1399px) {
    .banner-area4 .banner-img {
        margin-left: 50px
    }
}

@media(max-width:991px) {
    .banner-area4 .banner-img {
        display: none;
        visibility: hidden
    }
}

.banner-area4 .banner-img .banner-big-img {
    border-radius: 260px;
    position: relative;
    display: inline-block;
    z-index: 1
}

.banner-area4 .banner-img .banner-big-img::after {
    content: "";
    opacity: .1;
    border: 1px solid var(--theme-color);
    border-radius: 260px;
    position: absolute;
    left: 20px;
    top: 0;
    height: 98%;
    width: 100%;
    z-index: -1
}

.banner-area4 .banner-img .banner-big-img img {
    border-radius: 260px;
    max-width: 400px
}

@media(max-width:1500px) {
    .banner-area4 .banner-img .banner-big-img img {
        max-width: 350px
    }
}

@media(max-width:1399px) {
    .banner-area4 .banner-img .banner-big-img img {
        max-width: 350px
    }
}

.banner-area4 .banner-img .banner-sm-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: absolute;
    top: -130px;
    right: -75px
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area4 .banner-img .banner-sm-img {
        width: 120px;
        height: 120px;
        top: -90px
    }
}

@media(max-width:1399px) {
    .banner-area4 .banner-img .banner-sm-img {
        width: 120px;
        height: 120px;
        top: -90px
    }
}

@media(max-width:1199px) {
    .banner-area4 .banner-img .banner-sm-img {
        right: -30px
    }
}

.banner-area4 .banner-img .banner-sm-img::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(23, 22, 26, .3)
}

.banner-area4 .banner-img .banner-sm-img img {
    width: 150px;
    height: 150px;
    border-radius: 50%
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area4 .banner-img .banner-sm-img img {
        width: 120px;
        height: 120px
    }
}

@media(max-width:1399px) {
    .banner-area4 .banner-img .banner-sm-img img {
        width: 120px;
        height: 120px
    }
}

.banner-area4 .banner-img .banner-sm-img .down-arrow {
    position: absolute;
    right: -75px;
    top: 0
}

@media(max-width:1399px) {
    .banner-area4 .banner-img .banner-sm-img .down-arrow {
        display: none;
        visibility: hidden
    }
}

.banner-area4 .background-text-slider {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 180px;
    width: 100%
}

.banner-area4 .background-text-slider h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 98px;
    line-height: 154px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    margin-bottom: 0;
    line-height: 1;
    opacity: .03
}

@media(max-width:767px) {
    .banner-area4 .background-text-slider h2 {
        font-size: 70px
    }
}

.banner-area4 .scroll-down {
    position: absolute;
    bottom: 80px
}

@media(max-width:991px) {
    .banner-area4 .scroll-down {
        right: 50px;
        bottom: 50px
    }
}

@media(max-width:767px) {
    .banner-area4 .scroll-down {
        display: none;
        visibility: hidden
    }
}

.banner-area4 .scroll-down a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 12px;
    transition: .5s
}

.banner-area4 .scroll-down a svg {
    stroke: var(--theme-color);
    transition: .5s;
    transform: rotate(90deg)
}

.banner-area4 .scroll-down a:hover {
    color: var(--theme-color)
}

.banner-area4 .scroll-down a:hover svg {
    transform: rotate(45deg)
}

.banner-area4 .banner-video-area {
    position: absolute;
    left: 50%;
    transform: translateX(-50px);
    bottom: 70px
}

@media(max-width:991px) {
    .banner-area4 .banner-video-area {
        display: none;
        visibility: hidden
    }
}

.banner-area4 .banner-video-area img {
    opacity: .5;
    animation: rotate 8s linear infinite
}

@keyframes rotate {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.banner-area4 .banner-video-area .video-popup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1
}

.banner-area4 .banner-video-area .video-popup svg {
    fill: var(--white-color);
    animation: opcsc 1s infinite alternate
}

.main-nav-wrapper {
    transition: all .7s ease-in;
    position: relative;
    width: 100%;
    z-index: 9999
}

.main-nav-wrapper::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color2);
    z-index: 1
}

.main-nav-wrapper .sidebar-menu-contact {
    gap: 35px
}

.main-nav-wrapper .sidebar-menu-contact a {
    width: 45px;
    text-align: center
}

.main-nav-wrapper .mobile-logo-area {
    padding: 14px 0
}

.main-nav-wrapper .main-nav2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 5% !important;
    z-index: 99;
    overflow-y: auto;
    background-image: url(../img/home-5/home5-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    box-shadow: 4px 3px 20px rgba(16, 33, 34, .06);
    transition: all .7s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translateY(-10vh);
    opacity: 0;
    visibility: hidden;
    gap: 30px;
    height: 100%
}

.main-nav-wrapper .main-nav2::before {
    content: "Explore Pages.";
    position: absolute;
    top: 45%;
    right: 10%;
    transform: translateY(-50%);
    display: block;
    font-size: 220px;
    font-weight: 700;
    line-height: 1;
    font-family: var(--font-saira);
    color: var(--white-color);
    opacity: .1;
    z-index: 1;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: 0;
    height: 300px;
    letter-spacing: 5px;
    text-align: right;
    z-index: -1
}

@media(min-width:1200px) and (max-width:1399px) {
    .main-nav-wrapper .main-nav2::before {
        font-size: 200px
    }
}

@media(max-width:991px) {
    .main-nav-wrapper .main-nav2::before {
        display: none;
        visibility: hidden
    }
}

@media(min-width:576px) and (max-width:768px) {
    .main-nav-wrapper .main-nav2 {
        min-width: 100%;
        max-width: 100%
    }
}

@media(max-width:576px) {
    .main-nav-wrapper .main-nav2 {
        min-width: 100%;
        max-width: 100%
    }
}

.main-nav-wrapper .main-nav2::-webkit-scrollbar {
    width: 5px
}

.main-nav-wrapper .main-nav2::-webkit-scrollbar-track {
    background: #f1f1f1
}

.main-nav-wrapper .main-nav2::-webkit-scrollbar-thumb {
    background: #999
}

.main-nav-wrapper .main-nav2::-webkit-scrollbar-thumb:hover {
    background: var(--border-color)
}

.main-nav-wrapper .main-nav2 .menu-close-btn {
    position: absolute;
    right: 5%;
    top: 35px;
    opacity: 1;
    cursor: pointer;
    border: 1px solid var(--white-color);
    padding: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 30px;
    opacity: .8
}

.main-nav-wrapper .main-nav2 .menu-close-btn i {
    color: var(--white-color)
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul {
    float: none;
    text-align: left;
    padding: 80px 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 500px;
    margin-left: 0;
    margin-right: auto;
    z-index: 3
}

@media(min-width:1200px) and (max-width:1399px) {
    .main-nav-wrapper .main-nav2 .sidebar-menu-area>ul {
        padding: 45px 0 35px
    }
}

@media(max-width:767px) {
    .main-nav-wrapper .main-nav2 .sidebar-menu-area>ul {
        padding: 40px 0 30px
    }
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li {
    display: block;
    position: relative;
    padding: 5px;
    transition: all .2s;
    transform: translateY(50px) scaleY(0);
    opacity: 0
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li:last-child {
    border-bottom: 1px solid transparent
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li:hover a:before {
    width: 100%
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li>.bi {
    position: absolute;
    right: 41px;
    top: 26px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    color: var(--white-color)
}

@media(max-width:576px) {
    .main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li>.bi {
        top: 15px
    }
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li a {
    padding: 4px 0;
    display: inline-block;
    font-size: 3rem;
    font-weight: 700;
    color: var(--white-color);
    font-family: var(--font-saira);
    transition: all .6s ease;
    font-style: normal;
    text-decoration: none;
    transition: all .55s ease;
    position: relative;
    text-transform: uppercase
}

@supports(-webkit-text-stroke:1px var(--white-color)) {
    .main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li a {
        -webkit-text-stroke: 1px rgba(221, 221, 221, .8666666667);
        color: transparent
    }
}

@media(max-width:576px) {
    .main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li a {
        font-size: 2.2rem
    }
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li a::before {
    overflow: hidden;
    position: absolute;
    top: 5px;
    left: 0;
    width: 0;
    color: var(--white-color);
    white-space: nowrap;
    content: attr(data-hover);
    transition: all .8s cubic-bezier(.17, .67, .32, .87)
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li ul.sub-menu {
    position: static;
    min-width: 180px;
    background: 0 0;
    border: none;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    transform: none;
    transition: none;
    display: none;
    margin-top: 0 !important;
    list-style: none;
    padding-left: 20px
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li ul.sub-menu li {
    border: none;
    opacity: 1
}

.main-nav-wrapper .main-nav2 .sidebar-menu-area>ul>li ul.sub-menu li a {
    font-size: 22px;
    font-weight: 400;
    padding: 7px 5px;
    font-style: normal;
    text-transform: capitalize;
    -webkit-text-stroke: unset;
    -webkit-text-fill-color: unset;
    font-family: var(--font-saira);
    color: var(--white-color)
}

.main-nav-wrapper .main-nav2.show-menu {
    transform: none;
    opacity: 1;
    visibility: visible
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateY(50px) scaleY(0)
    }

    to {
        opacity: 1;
        transform: translateY(0) scaleY(1)
    }
}

.main-nav-wrapper .uesr-aera {
    width: 70px;
    height: 70px;
    line-height: 72px;
    border-radius: 50%;
    text-align: center;
    background: var(--primary-color2);
    display: inline-block;
    margin-left: auto;
    margin-right: auto
}

.main-nav-wrapper .uesr-aera .bi {
    font-size: 35px;
    color: var(--text-primary)
}

.main-nav-wrapper .mobile-menu {
    position: relative;
    top: 2px;
    padding: 0 5px;
    border-radius: 50%;
    display: inline-block
}

.main-container {
    max-width: 100%;
    min-width: 100%;
    overflow: hidden
}

.main-container .sidebar-wrapper {
    background-image: url(../img/home-5/home5-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    max-width: 95px;
    padding: 50px 22px;
    position: fixed;
    background-color: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    max-height: 100vh;
    overflow: hidden;
    z-index: 2;
    transition: .55s ease;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, .3)
}

@media(max-width:767px) {
    .main-container .sidebar-wrapper {
        max-width: 70px;
        padding: 50px 9px
    }
}

@media(max-width:991px) {
    .main-container .sidebar-wrapper {
        display: none
    }
}

.main-container .sidebar-wrapper .header-logo a img {
    max-width: unset
}

.main-container .sidebar-wrapper .sidebar-button {
    height: 42px;
    width: 42px;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s
}

.main-container .sidebar-wrapper .sidebar-button span {
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    display: inline-block;
    position: relative;
    transition: .5s
}

.main-container .sidebar-wrapper .sidebar-button span::before {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    top: -6px;
    transition: .5s
}

.main-container .sidebar-wrapper .sidebar-button span::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: .5s
}

.main-container .sidebar-wrapper .sidebar-button:hover {
    border-color: var(--theme-color)
}

.main-container .sidebar-wrapper .sidebar-button:hover span {
    background-color: var(--theme-color)
}

.main-container .sidebar-wrapper .sidebar-button:hover span::before {
    background-color: var(--theme-color)
}

.main-container .sidebar-wrapper .sidebar-button:hover span::after {
    background-color: var(--theme-color)
}

.main-container .sidebar-wrapper .header-btn {
    margin-bottom: 61px
}

.main-container .main-content {
    position: relative;
    margin-left: 95px;
    max-width: calc(100% - 95px);
    width: 100%;
    min-height: 100vh;
    transition: .55s ease
}

@media(max-width:991px) {
    .main-container .main-content {
        max-width: calc(100% - 0px);
        margin-left: 0
    }
}

.main-container .main-content .header5 {
    background-image: url(../img/home-5/home5-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    background-color: transparent;
    z-index: 9999;
    transition: .55s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 4%;
    border-bottom: 1px solid rgba(255, 255, 255, .3)
}

.main-container .main-content .header5.sticky {
    position: fixed;
    left: 0;
    top: 0
}

.main-container .main-content .header5 .header-logo a img {
    max-width: unset
}

.main-container .main-content .header5 .sidebar-button {
    height: 42px;
    width: 42px;
    border: 1px solid var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s
}

.main-container .main-content .header5 .sidebar-button span {
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    display: inline-block;
    position: relative;
    transition: .5s
}

.main-container .main-content .header5 .sidebar-button span::before {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    top: -6px;
    transition: .5s
}

.main-container .main-content .header5 .sidebar-button span::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: var(--white-color);
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: .5s
}

.main-container .main-content .header5 .sidebar-button:hover {
    border-color: var(--theme-color)
}

.main-container .main-content .header5 .sidebar-button:hover span {
    background-color: var(--theme-color)
}

.main-container .main-content .header5 .sidebar-button:hover span::before {
    background-color: var(--theme-color)
}

.main-container .main-content .header5 .sidebar-button:hover span::after {
    background-color: var(--theme-color)
}

.main-container .main-content .banner-area-wrapper {
    background-image: url(../img/home-5/home5-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden
}

.main-container .main-content .banner-area-wrapper .company-name {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 280px;
    width: 100%;
    border-right: 1px solid rgba(255, 255, 255, .3);
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, .3)
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .company-name {
        max-width: 220px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .company-name {
        max-width: 220px
    }
}

@media(max-width:1199px) {
    .main-container .main-content .banner-area-wrapper .company-name {
        display: none;
        visibility: hidden
    }
}

.main-container .main-content .banner-area-wrapper .company-name h2 {
    font-family: saira;
    font-style: normal;
    font-weight: 600;
    font-size: 150px;
    line-height: 1;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    opacity: .3;
    transform: rotate(90deg);
    margin-bottom: 0;
    margin-top: 120px
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .company-name h2 {
        margin-top: 72px;
        font-size: 130px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .company-name h2 {
        margin-top: 100px;
        font-size: 115px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area {
    margin-left: 280px;
    padding-top: 100px;
    position: relative
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area {
        margin-left: 220px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area {
        margin-left: 220px
    }
}

@media(max-width:1199px) {
    .main-container .main-content .banner-area-wrapper .banner-area {
        margin-left: 0
    }
}

@media(max-width:991px) {
    .main-container .main-content .banner-area-wrapper .banner-area {
        padding-right: 15px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area {
    position: absolute;
    right: 60px
}

@media(max-width:991px) {
    .main-container .main-content .banner-area-wrapper .banner-area .social-area {
        display: none;
        visibility: hidden
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul {
    margin: 0;
    padding: 0;
    gap: 30px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .social-area ul {
        gap: 30px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area .social-area ul {
        gap: 30px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul li {
    position: relative
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul li::after {
    content: "";
    height: 2.5px;
    width: 2.5px;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%)
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .social-area ul li::after {
        top: -20px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area .social-area ul li::after {
        top: -20px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul li:first-child::after {
    display: none;
    visibility: hidden
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul li a {
    font-style: 24px;
    color: var(--white-color);
    transition: .4s
}

.main-container .main-content .banner-area-wrapper .banner-area .social-area ul li a:hover {
    color: var(--theme-color)
}

@media(max-width:991px) {
    .main-container .main-content .banner-area-wrapper .banner-area .swiper-pagination1 {
        display: none;
        visibility: hidden
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-title {
    padding-left: 100px
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title {
        padding-left: 50px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title {
        padding-left: 50px
    }
}

@media(max-width:767px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title {
        padding-left: 15px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 70px;
    line-height: 1.4;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    margin-bottom: 15px
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
        font-size: 60px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
        font-size: 60px
    }
}

@media(max-width:991px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
        font-size: 55px
    }
}

@media(max-width:767px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
        font-size: 50px
    }
}

@media(max-width:576px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-title h1 {
        font-size: 40px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content {
    padding-left: 100px
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content {
        padding-left: 50px
    }
}

@media(max-width:1399px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content {
        padding-left: 50px
    }
}

@media(max-width:767px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content {
        padding-left: 15px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 35px;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 0
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .view-btn {
    padding-top: 40px
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .swiper-pagination1 {
    right: 10px
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .swiper-pagination1 {
        top: 70px
    }
}

@media(max-width:1500px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .swiper-pagination1 {
        top: 30px
    }
}

@media(max-width:1199px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .swiper-pagination1 {
        display: none;
        visibility: hidden
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area {
    margin-top: 125px;
    max-width: 170px;
    width: 100%
}

@media(min-width:1400px) and (max-width:1599px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area {
        margin-top: 70px
    }
}

@media(max-width:1500px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area {
        margin-top: 30px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area a span {
    width: 3px;
    height: 121px;
    background: var(--white-color);
    display: inline-block;
    position: relative;
    overflow: hidden
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-content .scroll-and-social-area .scroll-down-area a span::after {
    content: "";
    width: 100%;
    height: 25px;
    background: var(--theme-color);
    position: absolute;
    left: 0;
    top: 0;
    animation: up-down 1.5s linear infinite
}

@keyframes up-down {
    0% {
        transform: translateY(-30px)
    }

    100% {
        transform: translateY(121px)
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider {
    padding-top: 50px
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .banner-img {
    position: relative
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .banner-img::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(178.7deg, #1D1D1D 13.12%, rgba(117, 218, 180, 0.7) 98.89%);
    opacity: .6
}

@media(max-width:1700px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap {
        height: 100%
    }

    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider {
        height: 100%
    }

    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .swiper-wrapper {
        height: 100%
    }

    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .swiper-wrapper .swiper-slide {
        height: 100%
    }

    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .swiper-wrapper .swiper-slide .banner-img {
        height: 100%
    }

    .main-container .main-content .banner-area-wrapper .banner-area .banner-img-wrap .banner5-slider .swiper-wrapper .swiper-slide .banner-img img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider {
    border-top: 1px solid rgba(255, 255, 255, .3);
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    background: var(--black-color2);
    padding: 15px;
    width: 100%;
    overflow: hidden
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider h2 {
    width: 100%;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 27px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 20px;
    margin-bottom: 0
}

@media(max-width:576px) {
    .main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider h2 {
        font-size: 18px
    }
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider .js-marquee-wrapper {
    display: flex;
    gap: 30px
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider .js-marquee {
    display: flex;
    align-items: center;
    gap: 30px
}

.main-container .main-content .banner-area-wrapper .banner-area .banner-text-slider .js-marquee:last-child {
    display: none;
    visibility: hidden
}

.banner-area6 {
    background-image: url(../img/home-6/home6-banner-bg.png), linear-gradient(#1e1c22, #1e1c22);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden
}

@media(max-width:767px) {
    .banner-area6 {
        min-height: 500px
    }
}

.banner-area6 .banner-wrap {
    padding-left: 120px;
    padding-top: 86px;
    position: relative
}

.banner-area6 .banner-wrap .social-area {
    position: absolute;
    right: 60px;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%)
}

@media(max-width:991px) {
    .banner-area6 .banner-wrap .social-area {
        display: none;
        visibility: hidden
    }
}

.banner-area6 .banner-wrap .social-area ul {
    margin: 0;
    padding: 0;
    gap: 30px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area6 .banner-wrap .social-area ul {
        gap: 30px
    }
}

@media(max-width:1399px) {
    .banner-area6 .banner-wrap .social-area ul {
        gap: 30px
    }
}

.banner-area6 .banner-wrap .social-area ul li {
    position: relative
}

.banner-area6 .banner-wrap .social-area ul li::after {
    content: "";
    height: 2.5px;
    width: 2.5px;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%)
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area6 .banner-wrap .social-area ul li::after {
        top: -20px
    }
}

@media(max-width:1399px) {
    .banner-area6 .banner-wrap .social-area ul li::after {
        top: -20px
    }
}

.banner-area6 .banner-wrap .social-area ul li:first-child::after {
    display: none;
    visibility: hidden
}

.banner-area6 .banner-wrap .social-area ul li a {
    font-size: 20px;
    color: rgba(255, 255, 255, .7);
    transition: .4s
}

.banner-area6 .banner-wrap .social-area ul li a:hover {
    color: var(--theme-color)
}

@media(max-width:1500px) {
    .banner-area6 .banner-wrap {
        padding-left: 80px
    }
}

@media(max-width:1199px) {
    .banner-area6 .banner-wrap {
        padding-left: 30px
    }
}

@media(max-width:576px) {
    .banner-area6 .banner-wrap {
        padding-left: 15px;
        padding-right: 15px
    }
}

.banner-area6 .banner-wrap .banner-content {
    max-width: 740px;
    width: 100%
}

@media(max-width:1500px) {
    .banner-area6 .banner-wrap .banner-content {
        max-width: 525px
    }
}

@media(max-width:1399px) {
    .banner-area6 .banner-wrap .banner-content {
        padding-top: 100px;
        padding-bottom: 100px
    }
}

.banner-area6 .banner-wrap .banner-content h1 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 85px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 30px
}

@media(min-width:1400px) and (max-width:1599px) {
    .banner-area6 .banner-wrap .banner-content h1 {
        font-size: 60px
    }
}

@media(max-width:1399px) {
    .banner-area6 .banner-wrap .banner-content h1 {
        font-size: 55px
    }
}

@media(max-width:1199px) {
    .banner-area6 .banner-wrap .banner-content h1 {
        font-size: 50px
    }
}

@media(max-width:576px) {
    .banner-area6 .banner-wrap .banner-content h1 {
        font-size: 38px
    }
}

.banner-area6 .banner-wrap .banner-content h1 span {
    color: var(--theme-color);
    position: relative
}

.banner-area6 .banner-wrap .banner-content h1 span::after {
    content: url(../img/home-6/banner-text-vec.png);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -31%)
}

@media(max-width:991px) {
    .banner-area6 .banner-wrap .banner-content h1 span::after {
        display: none;
        visibility: hidden
    }
}

.banner-area6 .banner-wrap .banner-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #e4e4e4;
    margin-bottom: 55px
}

.banner-area6 .banner-wrap .banner-content .banner-btn-group {
    display: flex;
    align-items: center;
    gap: 60px
}

@media(max-width:1400px) {
    .banner-area6 .banner-wrap .banner-content .banner-btn-group {
        gap: 35px
    }
}

@media(max-width:576px) {
    .banner-area6 .banner-wrap .banner-content .banner-btn-group {
        font-size: 15px;
        flex-wrap: wrap
    }
}

.banner-area6 .banner-wrap .banner-img-wrap {
    max-width: 945px;
    width: 100%
}

@media(max-width:1399px) {
    .banner-area6 .banner-wrap .banner-img-wrap {
        display: flex;
        align-items: center
    }
}

@media(max-width:991px) {
    .banner-area6 .banner-wrap .banner-img-wrap {
        display: none;
        visibility: hidden
    }
}

.banner-area6 .banner-wrap .banner-img-wrap .banner-img {
    position: relative;
    overflow: hidden;
    transition: .5s
}

.banner-area6 .banner-wrap .banner-img-wrap .banner-img::after {
    content: "";
    height: 150%;
    width: 280px;
    background-color: rgba(6, 216, 137, .2);
    position: absolute;
    top: -120px;
    left: 50%;
    transform: translateX(-97%) rotate(-20deg);
    transform-origin: top;
    z-index: 1;
    transition: .5s
}

.home-dark {
    background: #22262a
}

.home4-about-section .about-left-img-wrap {
    max-width: 400px;
    width: 100%;
    position: relative;
    margin: 0 auto
}

.home4-about-section .about-left-img-wrap .big-img {
    border-radius: 190px;
    position: relative
}

.home4-about-section .about-left-img-wrap .big-img::after {
    content: "";
    background: linear-gradient(180deg, rgba(34, 38, 42, 0.6) 0%, rgba(34, 38, 42, 0.1) 100%);
    border-radius: 190px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.home4-about-section .about-left-img-wrap .big-img img {
    border-radius: 190px
}

.home4-about-section .about-left-img-wrap .sm-img {
    width: 148px;
    height: 148px;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    position: relative;
    position: absolute;
    bottom: 0;
    right: -45px
}

@media(max-width:576px) {
    .home4-about-section .about-left-img-wrap .sm-img {
        right: 0
    }
}

.home4-about-section .about-left-img-wrap .sm-img::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: var(--theme-color);
    opacity: .2;
    border-radius: 50%
}

.home4-about-section .about-left-img-wrap .sm-img img {
    width: 148px;
    height: 148px;
    border-radius: 50%
}

.home4-about-section .about-bottom {
    padding-top: 100px
}

@media(max-width:991px) {
    .home4-about-section .about-bottom {
        padding-top: 80px
    }
}

.home4-about-section .about-left-content .about-feature {
    margin-top: 25px;
    border-top: 1px solid rgba(255, 255, 255, .25);
    padding-top: 40px
}

.home4-about-section .about-left-content .about-feature ul {
    margin: 0;
    padding: 0;
    -moz-columns: 2;
    columns: 2
}

@media(max-width:576px) {
    .home4-about-section .about-left-content .about-feature ul {
        -moz-columns: 1;
        columns: 1
    }
}

.home4-about-section .about-left-content .about-feature ul li {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    color: var(--white-color);
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px
}

.home4-about-section .about-left-content .about-feature ul li:nth-child(even) {
    margin-bottom: 0
}

@media(max-width:576px) {
    .home4-about-section .about-left-content .about-feature ul li:nth-child(even) {
        margin-bottom: 25px
    }

    .home4-about-section .about-left-content .about-feature ul li:last-child {
        margin-bottom: 0
    }
}

.home4-services-section {
    background: #1b1f23;
    padding: 100px 0
}

.home4-services-section.two {
    background-color: transparent;
    padding: 0
}

@media(max-width:991px) {
    .home4-services-section {
        padding: 80px 0
    }
}

.home4-services-section .view-btn {
    padding-top: 70px
}

.home4-services-section .single-service-card {
    background-image: url(../img/home-4/home4-service-bg.png), linear-gradient(#17181C, #17181C);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
    padding: 40px 30px;
    transition: .5s
}

@media(max-width:576px) {
    .home4-services-section .single-service-card {
        padding: 35px 20px
    }
}

.home4-services-section .single-service-card .icon {
    margin-bottom: 25px
}

.home4-services-section .single-service-card .icon svg {
    fill: var(--theme-color)
}

.home4-services-section .single-service-card .services-content h3 {
    margin-bottom: 10px
}

.home4-services-section .single-service-card .services-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 27px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #e4e4e4;
    transition: .5s
}

.home4-services-section .single-service-card .services-content h3 a:hover {
    color: var(--theme-color)
}

.home4-services-section .single-service-card .services-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #e4e4e4
}

.home4-services-section .single-service-card .services-content .services-btn {
    padding-top: 33px
}

.home4-services-section .single-service-card:hover {
    background-image: url(../img/home-4/home4-service-bg2.png), linear-gradient(#17181C, #17181C)
}

.home4-work-section .single-work {
    position: relative;
    border-radius: 5px;
    transition: .5s
}

.home4-work-section .single-work .work-img {
    border-radius: 5px
}

.home4-work-section .single-work .work-img img {
    border-radius: 5px
}

@media(max-width:576px) {
    .home4-work-section .single-work .work-img img {
        width: 100%
    }
}

.home4-work-section .single-work .work-content {
    background: linear-gradient(180deg, rgba(29, 29, 29, 0) 23.76%, #1D1D1D 90.19%);
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 25px;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: .5s;
    opacity: 0
}

@media(max-width:1199px) {
    .home4-work-section .single-work .work-content {
        padding: 25px
    }
}

.home4-work-section .single-work .work-content h3 {
    margin-bottom: 5px;
    line-height: 1.1
}

.home4-work-section .single-work .work-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 28px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s;
    line-height: 1.1
}

@media(max-width:1199px) {
    .home4-work-section .single-work .work-content h3 a {
        font-size: 24px
    }
}

.home4-work-section .single-work .work-content h3 a:hover {
    color: var(--theme-color)
}

.home4-work-section .single-work .work-content span {
    font-family: var(--font-saira);
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    opacity: .5
}

.home4-work-section .single-work:hover .work-content {
    opacity: 1;
    transform: scaleY(1)
}

.home4-feature-area {
    background: #1b1f23;
    padding: 120px 7% 270px
}

@media(max-width:1399px) {
    .home4-feature-area {
        padding: 120px 3% 270px
    }
}

@media(max-width:991px) {
    .home4-feature-area {
        padding: 80px 3% 230px
    }
}

.home4-feature-area .section-title-4 p {
    padding-top: 0
}

.home4-feature-area .ml-5 {
    margin-left: -5px
}

.home4-feature-area .counter-area {
    width: 100%
}

.home4-feature-area .counter-area .devider {
    position: relative
}

.home4-feature-area .counter-area .devider::after {
    content: "";
    height: 105px;
    width: 2px;
    background: var(--white-color);
    opacity: .1;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

@media(max-width:991px) {
    .home4-feature-area .counter-area .devider::after {
        display: none;
        visibility: hidden
    }
}

@media(max-width:991px) {
    .home4-feature-area .counter-area .single-counter {
        text-align: center
    }
}

.home4-feature-area .counter-area .single-counter .icon svg {
    fill: var(--theme-color)
}

.home4-feature-area .counter-area .single-counter .content {
    padding-top: 25px
}

@media(max-width:991px) {
    .home4-feature-area .counter-area .single-counter .content {
        text-align: center
    }
}

.home4-feature-area .counter-area .single-counter .content .number {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 0
}

@media(max-width:991px) {
    .home4-feature-area .counter-area .single-counter .content .number {
        justify-content: center
    }
}

.home4-feature-area .counter-area .single-counter .content .number h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 38px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 0
}

.home4-feature-area .counter-area .single-counter .content .number span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    text-transform: capitalize;
    color: var(--white-color)
}

.home4-feature-area .counter-area .single-counter .content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #b5b5b5;
    margin-bottom: 0
}

.home4-trusted-client-area {
    margin-left: 8%;
    margin-right: 8%;
    margin-top: -150px;
    background: #161a1e;
    border-radius: 10px;
    padding: 50px;
    overflow: hidden
}

@media(max-width:1399px) {
    .home4-trusted-client-area {
        margin-left: 3%;
        margin-right: 3%;
        padding: 50px 25px
    }
}

.home4-trusted-client-area p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #b5b5b5;
    margin-bottom: 70px
}

.home4-trusted-client-area .js-marquee {
    display: flex;
    align-items: center
}

.home4-trusted-client-area .single-client {
    display: flex;
    justify-content: center;
    max-width: 180px;
    width: 100%
}

.home4-trusted-client-area .marque-wrap {
    overflow: hidden;
    padding: 0 50px
}

@media(max-width:1399px) {
    .home4-trusted-client-area .marque-wrap {
        padding: 0 25px
    }
}

.home4-testimonial-section .mt-35 {
    margin-top: 60px
}

@media(max-width:1399px) {
    .home4-testimonial-section .mt-35 {
        margin-top: 24px
    }
}

.home4-testimonial-section .mt--35 {
    margin-top: -35px
}

@media(max-width:991px) {
    .home4-testimonial-section .mt--35 {
        margin-top: 0;
        margin-top: 24px
    }
}

.home4-testimonial-section .mb-35 {
    margin-bottom: 35px
}

.home4-testimonial-section .testimonial-card4 {
    background: #1b1f23;
    border-radius: 10px;
    padding: 45px 35px;
    position: relative
}

@media(max-width:576px) {
    .home4-testimonial-section .testimonial-card4 {
        padding: 35px 20px
    }
}

.home4-testimonial-section .testimonial-card4 .quate-icon {
    position: absolute;
    left: 50%;
    top: 0
}

.home4-testimonial-section .testimonial-card4 .testimonial-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
    flex-wrap: wrap;
    gap: 10px
}

@media(max-width:576px) {
    .home4-testimonial-section .testimonial-card4 .testimonial-top {
        display: block;
        gap: 0
    }
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .author-area {
    display: flex;
    align-items: center;
    gap: 12px
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .author-area .author-img img {
    height: 50px;
    width: 50px;
    border-radius: 50%
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .author-area .author-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #e4e4e4;
    margin-bottom: 0
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .author-area .author-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .02em;
    color: var(--theme-color)
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .review-date-and-time {
    text-align: end
}

@media(max-width:576px) {
    .home4-testimonial-section .testimonial-card4 .testimonial-top .review-date-and-time {
        text-align: start;
        margin-left: 62px;
        padding-top: 5px
    }
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .review-date-and-time p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    text-align: right;
    color: var(--white-color);
    margin-bottom: 0;
    line-height: 1
}

@media(max-width:576px) {
    .home4-testimonial-section .testimonial-card4 .testimonial-top .review-date-and-time p {
        text-align: start
    }
}

.home4-testimonial-section .testimonial-card4 .testimonial-top .review-date-and-time span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: var(--white-color);
    opacity: .5
}

.home4-testimonial-section .testimonial-card4 .testimonial-content {
    margin-bottom: 28px
}

.home4-testimonial-section .testimonial-card4 .testimonial-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 0
}

.home4-testimonial-section .testimonial-card4 .testimonial-btm {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.home4-testimonial-section .testimonial-card4 .testimonial-btm .review-right ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px
}

.home4-testimonial-section .testimonial-card4 .testimonial-btm .review-right ul li i {
    font-size: 20px;
    color: #ffc107
}

.home4-pricing-plan {
    background: #1b1f23;
    padding: 100px 12%
}

@media(min-width:1400px) and (max-width:1599px) {
    .home4-pricing-plan {
        padding: 100px 8%
    }
}

@media(max-width:1399px) {
    .home4-pricing-plan {
        padding: 100px 6%
    }
}

@media(max-width:1199px) {
    .home4-pricing-plan {
        padding: 100px 3%
    }
}

@media(max-width:991px) {
    .home4-pricing-plan {
        padding: 80px 3%
    }
}

.home4-pricing-plan .section-title-4 nav {
    padding-top: 25px;
    display: flex;
    justify-content: center
}

.home4-pricing-plan .section-title-4 nav .nav-tabs {
    border-bottom: none;
    background: #161a1e;
    border-radius: 50px;
    padding: 10px
}

@media(max-width:576px) {
    .home4-pricing-plan .section-title-4 nav .nav-tabs {
        border-radius: 10px
    }
}

.home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    color: var(--white-color);
    border: none;
    padding: 13px 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 8px
}

@media(max-width:576px) {
    .home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link {
        width: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center
    }
}

.home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link span {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    color: #1b1f23;
    background: var(--theme-color);
    border-radius: 100%;
    padding: 2px 5px
}

.home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link:hover {
    border: none
}

.home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link:focus {
    border: none
}

.home4-pricing-plan .section-title-4 nav .nav-tabs .nav-link.active {
    background: #22262a;
    border: none
}

.home4-pricing-plan .pricing-card {
    background-image: url(../img/home-4/pricing-bg-1.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px
}

.home4-pricing-plan .pricing-card .pricing-top {
    padding: 40px 35px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

@media(max-width:1399px) {
    .home4-pricing-plan .pricing-card .pricing-top {
        padding: 40px 25px
    }
}

.home4-pricing-plan .pricing-card .pricing-top span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    line-height: 1;
    margin-bottom: 30px
}

.home4-pricing-plan .pricing-card .pricing-top h2 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 60px;
    line-height: 44px;
    color: var(--white-color)
}

.home4-pricing-plan .pricing-card .pricing-top h2 sup {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 25px;
    letter-spacing: .03em;
    color: var(--white-color);
    vertical-align: middle;
    top: -.7em
}

.home4-pricing-plan .pricing-card .pricing-top h2 sub {
    font-weight: 500;
    font-size: 20px;
    color: var(--theme-color);
    bottom: -.15em
}

.home4-pricing-plan .pricing-card .pricing-content {
    padding: 30px 35px 40px
}

@media(max-width:1399px) {
    .home4-pricing-plan .pricing-card .pricing-content {
        padding: 30px 25px 40px
    }
}

.home4-pricing-plan .pricing-card .pricing-content ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.home4-pricing-plan .pricing-card .pricing-content ul li {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-saira);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: #e3e3e3;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;
    padding-top: 20px
}

.home4-pricing-plan .pricing-card .pricing-content ul li:last-child {
    padding-bottom: 0;
    border-bottom: none
}

.home4-pricing-plan .pricing-card .pricing-content .pay-btn {
    display: flex;
    justify-content: center;
    padding-top: 50px
}

.home4-pricing-plan .pricing-card .pricing-content .pay-btn a {
    font-size: 17px;
    font-weight: 700;
    padding: 12px 62px
}

.home4-pricing-plan .pricing-card.two {
    background-image: url(../img/home-4/pricing-bg-1.png), linear-gradient(#22262A, #22262A)
}

.home4-pricing-plan .pricing-card.two .pricing-content .pay-btn a {
    background-color: transparent;
    border: 1px solid var(--theme-color);
    color: var(--theme-color)
}

.home4-pricing-plan .pricing-card.two .pricing-content .pay-btn a::after {
    background-color: var(--theme-color)
}

.home4-pricing-plan .pricing-card.two .pricing-content .pay-btn a:hover {
    color: var(--black-color2)
}

.home4-experts-section .single-team {
    position: relative;
    padding: 25px 20px 20px;
    z-index: 1;
    transition: .5s
}

.home4-experts-section .single-team::after {
    content: "";
    background-image: url(../img/home-3/team-bg-1.png), linear-gradient(#1E1E1E, #1E1E1E);
    border: 1px solid transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: .5s;
    z-index: -1
}

.home4-experts-section .single-team .team-img {
    position: relative;
    transition: .5s
}

.home4-experts-section .single-team .team-img .social-area {
    transition: .5s;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0);
    bottom: 15px
}

.home4-experts-section .single-team .team-img .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px
}

.home4-experts-section .single-team .team-img .social-area ul li a {
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px solid var(--theme-color);
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.home4-experts-section .single-team .team-img .social-area ul li a:hover {
    color: var(--black-color2)
}

.home4-experts-section .single-team .team-img img {
    border-radius: 5px;
    width: 100%
}

.home4-experts-section .single-team .team-content {
    padding-top: 17px;
    text-align: center
}

.home4-experts-section .single-team .team-content h5 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--white-color);
    margin-bottom: 0
}

.home4-experts-section .single-team .team-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    color: #b5b5b5
}

.home4-experts-section .single-team:hover::after {
    background-image: url(../img/home-3/team-bg-2.png), linear-gradient(#1E1E1E, #1E1E1E);
    border: 1px solid rgba(255, 255, 255, .1)
}

.home4-experts-section .single-team:hover .team-img .social-area {
    opacity: 1;
    transform: translateX(-50%) scale(1)
}

.home4-experts-section .swiper-pagination22 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: unset !important;
    bottom: unset !important;
    display: flex;
    gap: 15px
}

@media(max-width:767px) {
    .home4-experts-section .swiper-pagination22 {
        display: none;
        visibility: hidden
    }
}

.home4-experts-section .swiper-pagination22 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .5);
    opacity: 1;
    position: relative
}

.home4-experts-section .swiper-pagination22 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home4-experts-section .swiper-pagination22 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.home4-experts-section .swiper-pagination22 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.home4-experts-section .experts-card {
    transition: .5s
}

.home4-experts-section .experts-card .experts-img {
    position: relative;
    transition: .5s
}

.home4-experts-section .experts-card .experts-img img {
    border-radius: 5px 5px 0 0;
    width: 100%
}

.home4-experts-section .experts-card .experts-img .expert-social {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 25px 20px;
    display: flex;
    align-items: end;
    justify-content: center;
    background: linear-gradient(176.24deg, rgba(29, 29, 29, 0) 10.39%, #1D1D1D 86.62%);
    transform: scaleY(0);
    transform-origin: bottom;
    transition: .5s;
    opacity: 0
}

.home4-experts-section .experts-card .experts-img .expert-social ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px
}

.home4-experts-section .experts-card .experts-img .expert-social ul li a {
    font-size: 20px;
    color: var(--white-color);
    transition: .5s
}

.home4-experts-section .experts-card .experts-img .expert-social ul li a:hover {
    color: var(--theme-color)
}

.home4-experts-section .experts-card .experts-content {
    padding-top: 22px
}

.home4-experts-section .experts-card .experts-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    color: var(--white-color);
    margin-bottom: 3px;
    transition: .5s
}

.home4-experts-section .experts-card .experts-content h4:hover {
    color: var(--theme-color)
}

.home4-experts-section .experts-card .experts-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    color: #b5b5b5
}

.home4-experts-section .experts-card:hover .experts-img .expert-social {
    transform: scaleY(1);
    opacity: 1
}

.home4-experts-section .experts:nth-child(odd) {
    margin-top: 30px
}

@media(max-width:991px) {
    .home4-experts-section .experts {
        margin-bottom: 30px
    }

    .home4-experts-section .experts:nth-child(odd) {
        margin-top: 0
    }
}

.home4-insight-area .home4-insights-card {
    background: #1b1f23;
    border-radius: 10px;
    padding: 25px
}

@media(max-width:767px) {
    .home4-insight-area .home4-insights-card {
        padding: 25px 15px
    }
}

.home4-insight-area .home4-insights-card .insight-img img {
    border-radius: 5px;
    width: 100%
}

.home4-insight-area .home4-insights-card .insight-content {
    padding-top: 18px
}

.home4-insight-area .home4-insights-card .insight-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px
}

.home4-insight-area .home4-insights-card .insight-content ul li a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    color: #e5e5e5
}

.home4-insight-area .home4-insights-card .insight-content ul li:first-child a {
    color: var(--theme-color)
}

.home4-insight-area .home4-insights-card .insight-content h4 {
    margin-bottom: 14px
}

.home4-insight-area .home4-insights-card .insight-content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    line-height: 1.5;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s
}

@media(min-width:992px) and (max-width:1199px) {
    .home4-insight-area .home4-insights-card .insight-content h4 a {
        font-size: 22px
    }
}

@media(max-width:767px) {
    .home4-insight-area .home4-insights-card .insight-content h4 a {
        font-size: 22px
    }
}

.home4-insight-area .home4-insights-card .insight-content h4 a:hover {
    color: var(--theme-color)
}

.home4-insight-area .home4-insights-card .insight-content p {
    margin-bottom: 25px;
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #e4e4e4
}

.home4-insight-area .swiper-pagination5 {
    padding-top: 55px;
    width: unset;
    display: flex;
    align-items: center;
    gap: 15px
}

.home4-insight-area .swiper-pagination5 .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid var(--theme-color);
    opacity: 1;
    position: relative
}

.home4-insight-area .swiper-pagination5 .swiper-pagination-bullet::after {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home4-insight-area .swiper-pagination5 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.home4-insight-area .swiper-pagination5 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.home4-insight-area .swiper-pagination5.two {
    width: unset !important;
    left: unset !important;
    right: 60px;
    transform: unset;
    top: 125px
}

.home4-contact-area .contact-wrapper {
    position: relative;
    padding-bottom: 250px
}

@media(max-width:576px) {
    .home4-contact-area .contact-wrapper {
        padding-bottom: 300px
    }
}

.home4-contact-area .contact-wrapper h2 {
    margin-bottom: 0
}

.home4-contact-area .contact-wrapper .title {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 120px;
    line-height: 1;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, .08);
    margin-bottom: 30px
}

@media(max-width:991px) {
    .home4-contact-area .contact-wrapper .title {
        font-size: 90px
    }
}

@media(max-width:767px) {
    .home4-contact-area .contact-wrapper .title {
        font-size: 70px
    }
}

.home4-contact-area .contact-wrapper .content {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 220px;
    line-height: 1;
    text-transform: uppercase;
    color: var(--white-color);
    opacity: .08
}

@media(max-width:1399px) {
    .home4-contact-area .contact-wrapper .content {
        font-size: 160px
    }
}

@media(max-width:991px) {
    .home4-contact-area .contact-wrapper .content {
        font-size: 120px
    }
}

@media(max-width:767px) {
    .home4-contact-area .contact-wrapper .content {
        font-size: 90px
    }
}

.home4-contact-area .contact-wrapper .contact-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px
}

@media(max-width:1399px) {
    .home4-contact-area .contact-wrapper .contact-btn {
        bottom: 55px
    }
}

@media(max-width:991px) {
    .home4-contact-area .contact-wrapper .contact-btn {
        bottom: 35px
    }
}

@media(max-width:767px) {
    .home4-contact-area .contact-wrapper .contact-btn {
        bottom: 90px
    }
}

.home4-contact-area .contact-wrapper .contact-btn a {
    width: 259px;
    height: 259px;
    border: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 25px;
    text-transform: capitalize;
    color: var(--white-color);
    border-radius: 50%;
    background-image: url(../img/home-4/contact-btn-bg.png), linear-gradient(#1b1f23, #1b1f23);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    transition: .8s;
    text-align: start
}

.home4-contact-area .contact-wrapper .contact-btn a svg {
    stroke: var(--white-color);
    transition: .5s
}

.home4-contact-area .contact-wrapper .contact-btn a:hover {
    border-color: var(--theme-color);
    box-shadow: inset 0 0 0 10em var(--theme-color);
    color: var(--black-color2)
}

.home4-contact-area .contact-wrapper .contact-btn a:hover svg {
    transform: rotate(45deg);
    stroke: var(--black-color2)
}

.home-dark2 {
    background: #0f0f0f
}

.home3-partner-area {
    background: #1b1f23;
    padding: 30px 65px
}

@media(max-width:1399px) {
    .home3-partner-area {
        padding: 40px 35px
    }
}

@media(max-width:767px) {
    .home3-partner-area {
        padding: 40px 0
    }
}

.home3-partner-area .partner-title {
    position: relative
}

.home3-partner-area .partner-title::after {
    content: "";
    height: 110px;
    width: 1px;
    background-color: var(--white-color);
    opacity: .1;
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%)
}

@media(min-width:1400px) and (max-width:1599px) {
    .home3-partner-area .partner-title::after {
        right: 30px
    }
}

@media(max-width:1399px) {
    .home3-partner-area .partner-title::after {
        right: 5px
    }
}

@media(max-width:1199px) {
    .home3-partner-area .partner-title::after {
        display: none;
        visibility: hidden
    }
}

.home3-partner-area .partner-title h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 35px;
    line-height: 45px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 0;
    max-width: 250px;
    width: 100%
}

.home3-partner-area .partner-wrapper {
    height: 100%;
    display: flex;
    align-items: center
}

.home3-partner-area .partner-wrapper .marquee_text {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
    overflow: hidden
}

.home3-partner-area .partner-wrapper .marquee_text .js-marquee {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-right: 0
}

.home3-partner-area .partner-wrapper .marquee_text .js-marquee:last-child {
    display: none;
    visibility: hidden
}

.home3-partner-area .partner-wrapper .marquee_text .js-marquee img {
    opacity: .3;
    transition: .5s
}

.home3-partner-area .partner-wrapper .marquee_text .js-marquee img:hover {
    opacity: 1
}

.home3-partner-area .partner-wrapper .marquee_text .js-marquee-wrapper {
    display: flex;
    align-items: center;
    gap: 30px
}

.swiper-btn-group {
    display: flex;
    align-items: center;
    gap: 55px;
    z-index: 9
}

.swiper-btn-group .swiper-btn {
    height: 35px;
    width: 35px;
    border: 1px solid var(--theme-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.swiper-btn-group .swiper-btn i {
    color: var(--theme-color);
    transition: .5s
}

.swiper-btn-group .swiper-btn:hover {
    background-color: var(--theme-color)
}

.swiper-btn-group .swiper-btn:hover i {
    color: var(--white-color)
}

.home3-solution-section .solution-card {
    background-image: url(../img/home-3/pricing-bg-32.png), linear-gradient(#1E1E1E, #1E1E1E);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    border-radius: 5px;
    transition: .5s
}

@media(max-width:576px) {
    .home3-solution-section .solution-card {
        padding: 25px
    }
}

.home3-solution-section .solution-card .icon {
    margin-bottom: 30px
}

.home3-solution-section .solution-card .icon svg {
    fill: var(--theme-color)
}

.home3-solution-section .solution-card .solution-content h4 {
    margin-bottom: 15px
}

.home3-solution-section .solution-card .solution-content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 27px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #e4e4e4;
    transition: .5s
}

@media(max-width:767px) {
    .home3-solution-section .solution-card .solution-content h4 a {
        font-size: 24px
    }
}

.home3-solution-section .solution-card .solution-content h4 a:hover {
    color: var(--theme-color)
}

.home3-solution-section .solution-card .solution-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #e4e4e4
}

.home3-solution-section .solution-card .solution-content .solution-feature {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 28px
}

.home3-solution-section .solution-card .solution-content .solution-feature li {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .02em;
    color: #fff;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 12px
}

.home3-solution-section .solution-card .solution-content .solution-feature li svg {
    fill: var(--theme-color)
}

.home3-solution-section .solution-card .solution-content .solution-feature li:last-child {
    margin-bottom: 0
}

.home3-solution-section .solution-card:hover {
    background-image: url(../img/home-3/pricing-bg-32.png), linear-gradient(rgba(117, 218, 180, 0.1), rgba(117, 218, 180, 0.1))
}

.home3-about-section {
    padding: 0 13%
}

@media(max-width:1700px) {
    .home3-about-section {
        padding: 0 10%
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .home3-about-section {
        padding: 0 5%
    }
}

@media(max-width:1399px) {
    .home3-about-section {
        padding: 0 4%
    }
}

@media(max-width:1199px) {
    .home3-about-section {
        padding: 0 2%
    }
}

.home3-about-section .section-title-3 {
    padding-left: 50px;
    margin-bottom: 80px
}

@media(max-width:576px) {
    .home3-about-section .section-title-3 {
        padding-left: 0;
        margin-bottom: 60px
    }
}

.home3-about-section .about-left {
    display: flex;
    align-items: center;
    gap: 40px
}

@media(max-width:1399px) {
    .home3-about-section .about-left {
        gap: 20px
    }
}

@media(max-width:767px) {
    .home3-about-section .about-left {
        flex-wrap: wrap;
        gap: 40px
    }
}

.home3-about-section .about-left .about-img {
    position: relative
}

.home3-about-section .about-left .about-img::after {
    content: "";
    border: 1px solid rgba(6, 216, 137, .15);
    position: absolute;
    left: -15px;
    top: 15px;
    width: 100%;
    height: 100%;
    z-index: -1
}

.home3-about-section .about-left .about-content {
    max-width: 470px;
    width: 100%
}

@media(max-width:1399px) {
    .home3-about-section .about-left .about-content {
        max-width: 400px
    }
}

@media(max-width:767px) {
    .home3-about-section .about-left .about-content {
        max-width: 100%
    }
}

.home3-about-section .about-left .about-content h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 45px;
    line-height: 1.3;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 20px
}

@media(min-width:1400px) and (max-width:1599px) {
    .home3-about-section .about-left .about-content h2 {
        font-size: 38px
    }
}

@media(max-width:1399px) {
    .home3-about-section .about-left .about-content h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .home3-about-section .about-left .about-content h2 {
        font-size: 30px
    }
}

.home3-about-section .about-left .about-content>p {
    font-family: var(--theme-color);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 50px
}

.home3-about-section .about-left .about-content .devider {
    border-bottom: 1px solid #d9d9d9;
    position: relative
}

.home3-about-section .about-left .about-content .devider::after {
    content: "";
    height: 21px;
    width: 21px;
    border: 3px solid var(--white-color);
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 22%;
    top: 50%;
    transform: translateY(-50%)
}

.home3-about-section .about-left .about-content .devider::before {
    content: "";
    height: 21px;
    width: 21px;
    border: 3px solid var(--white-color);
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    right: 22%;
    top: 50%;
    transform: translateY(-50%)
}

.home3-about-section .about-left .about-content .about-feature {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 30px;
    -moz-columns: 2;
    columns: 2
}

@media(max-width:576px) {
    .home3-about-section .about-left .about-content .about-feature {
        -moz-columns: 1;
        columns: 1
    }
}

.home3-about-section .about-left .about-content .about-feature li {
    text-align: center
}

@media(max-width:576px) {
    .home3-about-section .about-left .about-content .about-feature li {
        margin-bottom: 25px
    }

    .home3-about-section .about-left .about-content .about-feature li:last-child {
        margin-bottom: 0
    }
}

.home3-about-section .about-left .about-content .about-feature li h5 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 12px
}

@media(min-width:1400px) and (max-width:1599px) {
    .home3-about-section .about-left .about-content .about-feature li h5 {
        font-size: 18px
    }
}

@media(max-width:1399px) {
    .home3-about-section .about-left .about-content .about-feature li h5 {
        font-size: 18px
    }
}

.home3-about-section .about-left .about-content .about-feature li p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .03em;
    color: #b5b5b5
}

@media(max-width:991px) {
    .home3-about-section .about-right {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

@media(max-width:767px) {
    .home3-about-section .about-right {
        display: none
    }
}

.home3-about-section .about-right .about-img {
    position: relative;
    margin-bottom: 50px;
    display: inline-block
}

.home3-about-section .about-right .about-img::after {
    content: "";
    border: 1px solid rgba(6, 216, 137, .15);
    position: absolute;
    right: -10px;
    top: -10px;
    width: 100%;
    height: 100%;
    z-index: -1
}

.home3-about-section .about-right .about-exprience {
    height: 200px;
    width: 200px;
    margin: 0 auto;
    border-radius: 50%;
    background-image: url(../img/home-3/exp-bg.png), linear-gradient(var(--theme-color), var(--theme-color));
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.home3-about-section .about-right .about-exprience .years {
    position: absolute;
    text-align: center
}

.home3-about-section .about-right .about-exprience .years h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 45px;
    line-height: 38px;
    color: #263238;
    margin-bottom: 0
}

.home3-about-section .about-right .about-exprience .years h2 span {
    font-size: 28px;
    font-weight: 400
}

.home3-success-stories-area {
    background: #1b1f23;
    padding: 100px 8%
}

.home3-success-stories-area.two {
    background-color: transparent;
    padding: 0 8%
}

@media(max-width:1700px) {
    .home3-success-stories-area.two {
        padding: 0 3%
    }
}

@media(max-width:1199px) {
    .home3-success-stories-area.two {
        padding: 0 0%
    }
}

@media(max-width:1700px) {
    .home3-success-stories-area {
        padding: 100px 3%
    }
}

@media(max-width:1199px) {
    .home3-success-stories-area {
        padding: 100px 0%
    }
}

.home3-success-stories-area .swiper-slide:nth-child(even) {
    margin-top: 30px
}

@media(max-width:1199px) {
    .home3-success-stories-area .swiper-slide:nth-child(even) {
        margin-top: 0
    }
}

.home3-success-stories-area .success-storie-card {
    position: relative;
    border-radius: 5px;
    transition: .5s
}

.home3-success-stories-area .success-storie-card .success-img {
    border-radius: 5px
}

.home3-success-stories-area .success-storie-card .success-img img {
    border-radius: 5px;
    width: 100%
}

.home3-success-stories-area .success-storie-card .success-content {
    position: absolute;
    z-index: 1;
    left: 25px;
    right: 25px;
    bottom: 25px;
    background-image: url(../img/home-3/success-bg1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 35px 25px;
    border-radius: 5px
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content {
        padding: 35px 20px
    }
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content {
        padding: 25px 20px
    }
}

.home3-success-stories-area .success-storie-card .success-content span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .05em;
    text-transform: capitalize;
    color: #b5b5b5;
    display: inline-block;
    position: relative;
    margin-bottom: 15px
}

.home3-success-stories-area .success-storie-card .success-content span::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #06d889;
    border-radius: 5px;
    position: absolute;
    left: 0;
    bottom: -3px
}

.home3-success-stories-area .success-storie-card .success-content h3 {
    margin-bottom: 0
}

.home3-success-stories-area .success-storie-card .success-content h3 a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #fff;
    transition: .5s
}

@media(max-width:1399px) {
    .home3-success-stories-area .success-storie-card .success-content h3 a {
        font-size: 24px
    }
}

.home3-success-stories-area .success-storie-card .success-content h3 a:hover {
    color: var(--theme-color)
}

.home3-success-stories-area .success-storie-card .success-content .view-btn {
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%)
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center
}

.home3-success-stories-area .success-storie-card .success-content .view-btn a svg {
    stroke: #1b1f23;
    transform: rotate(90deg);
    transition: .5s
}

.home3-success-stories-area .success-storie-card:hover .success-content .view-btn a svg {
    transform: rotate(45deg)
}

.home3-success-stories-area .swiper-pagination11 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: unset !important;
    bottom: unset !important;
    display: flex;
    gap: 15px
}

@media(max-width:767px) {
    .home3-success-stories-area .swiper-pagination11 {
        display: none;
        visibility: hidden
    }
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .5);
    opacity: 1;
    position: relative
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.home3-success-stories-area .swiper-pagination11 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.home3-testimonil-area .home3-testimonial-slider {
    margin-top: 40px;
    padding-top: 40px
}

@media(max-width:767px) {
    .home3-testimonil-area .home3-testimonial-slider {
        margin-top: 0;
        padding-top: 0
    }
}

.home3-testimonil-area .testimonial-card3 {
    background: #1e1e1e;
    border-radius: 10px;
    padding: 40px 35px 35px;
    position: relative;
    margin-bottom: 50px;
    transition: .35s
}

.home3-testimonil-area .testimonial-card3::after {
    content: "";
    width: 60px;
    height: 28px;
    background: #1e1e1e;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;
    -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    opacity: 0;
    transition: .35s
}

@media(max-width:576px) {
    .home3-testimonil-area .testimonial-card3 {
        padding: 35px 20px 30px
    }
}

.home3-testimonil-area .testimonial-card3 .quate-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transition: .35s;
    opacity: 0
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .author-area {
    display: flex;
    align-items: center;
    gap: 12px
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .author-area .author-img img {
    height: 50px;
    width: 50px;
    border-radius: 50%
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .author-area .author-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #e4e4e4;
    margin-bottom: 0
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .author-area .author-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .02em;
    color: var(--theme-color)
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .review-date-and-time {
    text-align: end
}

@media(max-width:576px) {
    .home3-testimonil-area .testimonial-card3 .testimonial-btm .review-date-and-time {
        text-align: start
    }
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .review-date-and-time p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    text-align: right;
    color: var(--white-color);
    margin-bottom: 0;
    line-height: 1
}

.home3-testimonil-area .testimonial-card3 .testimonial-btm .review-date-and-time span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: var(--white-color);
    opacity: .5
}

.home3-testimonil-area .testimonial-card3 .testimonial-content {
    margin-bottom: 28px
}

.home3-testimonil-area .testimonial-card3 .testimonial-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 0
}

.home3-testimonil-area .testimonial-card3 .testimonial-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 23px
}

.home3-testimonil-area .testimonial-card3 .testimonial-top .review-right ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px
}

.home3-testimonil-area .testimonial-card3 .testimonial-top .review-right ul li i {
    font-size: 20px;
    color: #ffc107
}

.home3-testimonil-area .author-img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin: 0 auto;
    opacity: 0;
    transition: .35s
}

.home3-testimonil-area .author-img img {
    height: 90px;
    width: 90px;
    border-radius: 50%
}

.home3-testimonil-area .swiper-slide-active {
    margin-top: -40px
}

@media(max-width:767px) {
    .home3-testimonil-area .swiper-slide-active {
        margin-top: 0
    }
}

.home3-testimonil-area .swiper-slide-active .author-img {
    opacity: 1
}

.home3-testimonil-area .swiper-slide-active .testimonial-card3::after {
    opacity: 1
}

.home3-testimonil-area .swiper-slide-active .testimonial-card3 .quate-icon {
    opacity: 1
}

.home3-testimonil-area .swiper-slide-prev {
    position: relative
}

.home3-testimonil-area .swiper-slide-prev .testimonial-card3::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #0F0F0F 65.02%, rgba(15, 15, 15, 0) 140.05%)
}

.home3-testimonil-area .swiper-slide-next {
    position: relative
}

.home3-testimonil-area .swiper-slide-next .testimonial-card3::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(15, 15, 15, 0) -40%, #0F0F0F 53.05%)
}

.home3-pricing-plan-area nav {
    padding-top: 25px;
    display: flex;
    justify-content: center
}

.home3-pricing-plan-area nav .nav-tabs {
    border-bottom: none;
    background: #161a1e;
    border-radius: 10px;
    padding: 10px;
    gap: 15px
}

.home3-pricing-plan-area nav .nav-tabs .nav-link {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
    color: var(--white-color);
    padding: 13px 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #22262a
}

@media(max-width:576px) {
    .home3-pricing-plan-area nav .nav-tabs .nav-link {
        width: 100%;
        display: flex;
        justify-content: center
    }
}

.home3-pricing-plan-area nav .nav-tabs .nav-link span {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    color: #1b1f23;
    background: var(--theme-color);
    border-radius: 100%;
    padding: 2px 5px
}

.home3-pricing-plan-area nav .nav-tabs .nav-link.active {
    background: #22262a
}

.home3-pricing-plan-area .pricing-card {
    background-image: url(../img/home-3/pricing-bg-31.png), linear-gradient(#121212, #121212);
    border: 1px solid #1e1e1e;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative
}

.home3-pricing-plan-area .pricing-card .pricing-top {
    padding: 40px 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

@media(max-width:1399px) {
    .home3-pricing-plan-area .pricing-card .pricing-top {
        padding: 40px 25px
    }
}

.home3-pricing-plan-area .pricing-card .pricing-top span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    line-height: 1;
    margin-bottom: 30px
}

.home3-pricing-plan-area .pricing-card .pricing-top h2 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 60px;
    line-height: 44px;
    color: var(--white-color)
}

.home3-pricing-plan-area .pricing-card .pricing-top h2 sup {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 25px;
    letter-spacing: .03em;
    color: var(--white-color);
    vertical-align: middle;
    top: -.7em
}

.home3-pricing-plan-area .pricing-card .pricing-top h2 sub {
    font-weight: 500;
    font-size: 20px;
    color: var(--theme-color);
    bottom: -.15em
}

.home3-pricing-plan-area .pricing-card .pricing-content {
    padding: 30px 35px 40px
}

@media(max-width:1399px) {
    .home3-pricing-plan-area .pricing-card .pricing-content {
        padding: 30px 25px 40px
    }
}

.home3-pricing-plan-area .pricing-card .pricing-content ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.home3-pricing-plan-area .pricing-card .pricing-content ul li {
    display: flex;
    align-items: center;
    gap: 7px;
    font-family: var(--font-saira);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: #e3e3e3;
    padding-bottom: 12px;
    padding-top: 12px
}

.home3-pricing-plan-area .pricing-card .pricing-content ul li:last-child {
    padding-bottom: 0;
    border-bottom: none
}

.home3-pricing-plan-area .pricing-card .pricing-content ul li svg circle {
    fill: var(--theme-color)
}

.home3-pricing-plan-area .pricing-card .pricing-content ul li svg path {
    fill: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card .pricing-content .pay-btn {
    padding-top: 50px
}

.home3-pricing-plan-area .pricing-card .pricing-content .pay-btn a {
    font-size: 17px;
    font-weight: 700;
    padding: 12px 62px
}

.home3-pricing-plan-area .pricing-card.two {
    background-image: url(../img/home-3/pricing-bg-32.png), linear-gradient(#06D889, #06D889)
}

.home3-pricing-plan-area .pricing-card.two .pricing-top span {
    color: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card.two .pricing-top h2 {
    color: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card.two .pricing-top h2 sup {
    color: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card.two .pricing-top h2 sub {
    color: var(--white-color)
}

.home3-pricing-plan-area .pricing-card.two .pricing-top .offer-tag {
    position: relative
}

.home3-pricing-plan-area .pricing-card.two .pricing-top .offer-tag h5 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: .03em;
    color: var(--white-color);
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center
}

.home3-pricing-plan-area .pricing-card.two .pricing-top .offer-tag h5 span {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 0;
    color: var(--white-color)
}

.home3-pricing-plan-area .pricing-card.two .pricing-content ul li {
    color: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card.two .pricing-content ul li svg circle {
    fill: var(--black-color2)
}

.home3-pricing-plan-area .pricing-card.two .pricing-content ul li svg path {
    fill: var(--theme-color)
}

.home3-pricing-plan-area .pricing-card.two .pricing-content .pay-btn {
    padding-top: 50px
}

.home3-pricing-plan-area .pricing-card.two .pricing-content .pay-btn a {
    background-color: #1b1f23;
    color: #fff
}

.home3-pricing-plan-area .pricing-card.two .pricing-content .pay-btn a::after {
    background-color: #fff
}

.home3-pricing-plan-area .pricing-card.two .pricing-content .pay-btn a:hover {
    color: #1b1f23
}

.home3-contact-area {
    /* background-image: url(../pics/epagingfamily.png); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    min-height: 575px;
    position: relative;
    z-index: 1
}

.home3-contact-area::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(15, 15, 15, 0.22) 0%, #0F0F0F 100%);
    z-index: -1
}

.home3-contact-area::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(15, 15, 15, 0.13) 0%, #0F0F0F 100%);
    z-index: -1
}

.home3-contact-area .contact-wrapper {
    position: relative;
    z-index: 1;
    padding-top: 115px
}

.home3-contact-area .contact-wrapper .marquee_text {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 100px;
    letter-spacing: .03em;
    text-transform: uppercase;
    z-index: -1;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, .12);
    margin-bottom: 0;
    overflow: hidden
}

.home3-contact-area .contact-wrapper .contact-btn {
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: center;
    margin-top: -50px
}

.home3-contact-area .contact-wrapper .contact-btn a {
    width: 250px;
    height: 250px;
    border: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 25px;
    text-transform: capitalize;
    color: var(--white-color);
    border-radius: 50%;
    background-image: url(../img/home-4/contact-btn-bg.png), linear-gradient(#1b1f23, #1b1f23);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    transition: .8s;
    text-align: start
}

.home3-contact-area .contact-wrapper .contact-btn a svg {
    stroke: var(--white-color);
    transition: .5s
}

.home3-contact-area .contact-wrapper .contact-btn a:hover {
    border-color: var(--theme-color);
    box-shadow: inset 0 0 0 10em var(--theme-color);
    color: var(--black-color2)
}

.home3-contact-area .contact-wrapper .contact-btn a:hover svg {
    transform: rotate(45deg);
    stroke: var(--black-color2)
}

.home3-team-area .single-team {
    position: relative;
    padding: 25px 20px 20px;
    z-index: 1;
    transition: .5s
}

.home3-team-area .single-team::after {
    content: "";
    background-image: url(../img/home-3/team-bg-1.png), linear-gradient(#1E1E1E, #1E1E1E);
    border: 1px solid transparent;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: .5s;
    z-index: -1
}

.home3-team-area .single-team .team-img {
    position: relative;
    transition: .5s
}

.home3-team-area .single-team .team-img .social-area {
    transition: .5s;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0);
    bottom: 15px
}

.home3-team-area .single-team .team-img .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px
}

.home3-team-area .single-team .team-img .social-area ul li a {
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 1px solid var(--theme-color);
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.home3-team-area .single-team .team-img .social-area ul li a:hover {
    color: var(--black-color2)
}

.home3-team-area .single-team .team-img img {
    border-radius: 5px;
    width: 100%
}

.home3-team-area .single-team .team-content {
    padding-top: 17px;
    text-align: center
}

.home3-team-area .single-team .team-content h5 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--white-color);
    margin-bottom: 0
}

.home3-team-area .single-team .team-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    color: #b5b5b5
}

.home3-team-area .single-team:hover::after {
    background-image: url(../img/home-3/team-bg-2.png), linear-gradient(#1E1E1E, #1E1E1E);
    border: 1px solid rgba(255, 255, 255, .1)
}

.home3-team-area .single-team:hover .team-img .social-area {
    opacity: 1;
    transform: translateX(-50%) scale(1)
}

.home3-team-area .swiper-pagination22 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: unset !important;
    bottom: unset !important;
    display: flex;
    gap: 15px
}

@media(max-width:767px) {
    .home3-team-area .swiper-pagination22 {
        display: none;
        visibility: hidden
    }
}

.home3-team-area .swiper-pagination22 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .5);
    opacity: 1;
    position: relative
}

.home3-team-area .swiper-pagination22 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home3-team-area .swiper-pagination22 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.home3-team-area .swiper-pagination22 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.home3-team-area .experts-card {
    transition: .5s
}

.home3-team-area .experts-card .experts-img {
    position: relative;
    transition: .5s
}

.home3-team-area .experts-card .experts-img img {
    border-radius: 5px 5px 0 0;
    width: 100%
}

.home3-team-area .experts-card .experts-img .expert-social {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 25px 20px;
    display: flex;
    align-items: end;
    justify-content: center;
    background: linear-gradient(176.24deg, rgba(29, 29, 29, 0) 10.39%, #1D1D1D 86.62%);
    transform: scaleY(0);
    transform-origin: bottom;
    transition: .5s;
    opacity: 0
}

.home3-team-area .experts-card .experts-img .expert-social ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px
}

.home3-team-area .experts-card .experts-img .expert-social ul li a {
    font-size: 20px;
    color: var(--white-color);
    transition: .5s
}

.home3-team-area .experts-card .experts-img .expert-social ul li a:hover {
    color: var(--theme-color)
}

.home3-team-area .experts-card .experts-content {
    padding-top: 22px
}

.home3-team-area .experts-card .experts-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    color: var(--white-color);
    margin-bottom: 3px;
    transition: .5s
}

.home3-team-area .experts-card .experts-content h4:hover {
    color: var(--theme-color)
}

.home3-team-area .experts-card .experts-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    color: #b5b5b5
}

.home3-team-area .experts-card:hover .experts-img .expert-social {
    transform: scaleY(1);
    opacity: 1
}

.home3-team-area .experts:nth-child(odd) {
    margin-top: 30px
}

@media(max-width:991px) {
    .home3-team-area .experts {
        margin-bottom: 30px
    }

    .home3-team-area .experts:nth-child(odd) {
        margin-top: 0
    }
}

.home3-blog-area .single-blog {
    border-radius: 5px;
    transition: .5s
}

.home3-blog-area .single-blog .blog-img {
    position: relative;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    transition: .5s
}

.home3-blog-area .single-blog .blog-img img {
    border-radius: 5px 5px 0 0;
    transition: .5s
}

@media(max-width:991px) {
    .home3-blog-area .single-blog .blog-img img {
        width: 100%
    }
}

.home3-blog-area .single-blog .blog-img .blog-tag {
    position: absolute;
    top: 0;
    right: 0
}

.home3-blog-area .single-blog .blog-img .blog-tag a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    color: var(--black-color2);
    background: var(--theme-color);
    border-radius: 0 5px 0 0;
    padding: 10px 16px;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.home3-blog-area .single-blog .blog-img .blog-tag a::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    border-radius: 0 2px 0 0;
    background-color: #0a1019;
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.home3-blog-area .single-blog .blog-img .blog-tag a:hover {
    color: var(--white-color)
}

.home3-blog-area .single-blog .blog-img .blog-tag a:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.home3-blog-area .single-blog .blog-content {
    background: #151515;
    padding: 20px 25px;
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-meta {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px
}

.home3-blog-area .single-blog .blog-content .blog-meta li a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    color: #b5b5b5;
    transition: .4s
}

.home3-blog-area .single-blog .blog-content .blog-meta li a:hover {
    color: var(--theme-color)
}

.home3-blog-area .single-blog .blog-content h4 {
    margin-bottom: 0
}

.home3-blog-area .single-blog .blog-content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    line-height: 1.5;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .4s
}

@media(max-width:1199px) {
    .home3-blog-area .single-blog .blog-content h4 a {
        font-size: 22px
    }
}

.home3-blog-area .single-blog .blog-content h4 a:hover {
    color: var(--theme-color)
}

.home3-blog-area .single-blog .blog-content p {
    font-family: var(--font-saira);
    font-size: 15px;
    color: #b5b5b5;
    padding-top: 5px
}

.home3-blog-area .single-blog .blog-content .blog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 10px
}

.home3-blog-area .single-blog .blog-content .blog-footer .read-btn a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    gap: 6px;
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-footer .read-btn a svg {
    stroke: var(--theme-color);
    transform: rotate(45deg);
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-footer .read-btn a:hover {
    color: var(--white-color)
}

.home3-blog-area .single-blog .blog-content .blog-footer .read-btn a:hover svg {
    stroke: var(--white-color);
    transform: rotate(90deg)
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area {
    display: flex;
    align-items: center;
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area span {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 14px;
    padding: 4px 12px;
    margin-right: -28px;
    transform: scaleX(0);
    transition: .5s;
    transform-origin: right
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul li {
    position: relative
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul li a {
    color: var(--white-color);
    font-size: 18px;
    display: flex;
    transition: .5s
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul li a:hover {
    color: var(--theme-color)
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul li::after {
    content: "";
    height: 2.5px;
    width: 2.5px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    background-color: rgba(255, 255, 255, .2)
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area ul li:first-child::after {
    display: none;
    visibility: hidden
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area:hover span {
    opacity: 0
}

.home3-blog-area .single-blog .blog-content .blog-footer .social-area:hover ul {
    transform: scaleX(1);
    position: relative;
    z-index: 9
}

.home3-blog-area .single-blog:hover .blog-img img {
    transform: scale(1.05)
}

.home3-blog-area .single-blog:hover .blog-content {
    background: #292929
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-about-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-about-area .about-content .section-title-5 {
    margin-bottom: 20px
}

.home5-about-area .about-content p {
    font-family: saira;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.home5-about-area .about-content .about-featue {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 2;
    columns: 2;
    padding-top: 35px;
    margin-bottom: 35px
}

@media(max-width:576px) {
    .home5-about-area .about-content .about-featue {
        -moz-columns: 1;
        columns: 1
    }
}

.home5-about-area .about-content .about-featue li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    color: #d7d7d7;
    margin-bottom: 30px
}

.home5-about-area .about-content .about-featue li:nth-child(even) {
    margin-bottom: 0
}

@media(max-width:576px) {
    .home5-about-area .about-content .about-featue li:nth-child(even) {
        margin-bottom: 30px
    }
}

@media(max-width:576px) {
    .home5-about-area .about-content .about-featue li:last-child {
        margin-bottom: 0
    }
}

.home5-about-area .about-content .about-featue li svg {
    fill: var(--theme-color)
}

.home5-about-area .about-img-wrap {
    height: 100%;
    display: flex;
    justify-content: end;
    position: relative
}

@media(max-width:767px) {
    .home5-about-area .about-img-wrap {
        justify-content: center
    }
}

.home5-about-area .about-img-wrap .about-img {
    position: relative;
    display: block;
    z-index: 1
}

@media(max-width:767px) {
    .home5-about-area .about-img-wrap .about-img {
        display: none;
        visibility: hidden
    }
}

@media(max-width:1199px) {
    .home5-about-area .about-img-wrap .about-img img {
        height: 95%
    }
}

.home5-about-area .about-img-wrap .about-img::after {
    content: "";
    position: absolute;
    left: -13px;
    top: -13px;
    height: 92%;
    width: 106%;
    border: 1px solid rgba(6, 216, 137, .25);
    z-index: -1
}

.home5-about-area .about-img-wrap .about-img::before {
    content: "";
    height: 94%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(29, 29, 29, 0.42) 0%, rgba(29, 29, 29, 0.4) 100%)
}

.home5-about-area .about-img-wrap .about-video-area {
    position: absolute;
    left: 0;
    bottom: -50px;
    z-index: 2;
    border-radius: 210px
}

@media(max-width:767px) {
    .home5-about-area .about-img-wrap .about-video-area {
        position: relative;
        left: initial;
        bottom: initial;
        display: flex;
        align-items: center
    }
}

.home5-about-area .about-img-wrap .about-video-area::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(29, 29, 29, .5);
    border-radius: 210px
}

.home5-about-area .about-img-wrap .about-video-area .about-video-btn {
    height: 68px;
    width: 68px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.home5-about-area .about-img-wrap .about-video-area .about-video-btn i {
    color: rgba(255, 255, 255, .5);
    font-size: 50px
}

.home5-about-area .about-img-wrap .about-video-area img {
    border-radius: 210px
}

.home5-services-section {
    background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-services-section {
        padding-left: 70px;
        padding-right: 70px
    }
}

@media(max-width:991px) {
    .home5-services-section {
        padding: 80px 0
    }
}

.home5-services-section.two {
    background-image: unset;
    padding: 0
}

.home5-services-section .solution-card {
    background: #171717;
    border: 1px solid rgba(255, 255, 255, .07);
    border-radius: 5px;
    padding: 30px;
    transition: .5s
}

@media(max-width:576px) {
    .home5-services-section .solution-card {
        padding: 25px 20px
    }
}

.home5-services-section .solution-card .icon svg {
    fill: var(--theme-color)
}

.home5-services-section .solution-card .icon {
    margin-bottom: 30px;
    position: relative
}

.home5-services-section .solution-card .icon .icon-bg {
    position: absolute;
    left: -30px;
    top: -30px
}

.home5-services-section .solution-card .icon svg {
    fill: var(--theme-color)
}

.home5-services-section .solution-card .solution-content h4 {
    margin-bottom: 15px
}

.home5-services-section .solution-card .solution-content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 27px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #e4e4e4;
    transition: .5s
}

@media(max-width:767px) {
    .home5-services-section .solution-card .solution-content h4 a {
        font-size: 24px
    }
}

.home5-services-section .solution-card .solution-content h4 a:hover {
    color: var(--theme-color)
}

.home5-services-section .solution-card .solution-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 40px
}

.home5-services-section .solution-card:hover {
    border: 1px solid rgba(117, 218, 180, .5)
}

.home5-process-area {
    position: relative
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-process-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-process-area .achievement-text-slider {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%
}

.home5-process-area .achievement-text-slider .marquee_text2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 40px;
    display: flex;
    color: rgba(255, 255, 255, .1);
    margin-bottom: 0
}

.home5-process-area .achievement-text-slider .marquee_text2 .js-marquee {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px
}

.home5-process-area .achievement-text-slider .marquee_text2 .js-marquee span {
    color: rgba(6, 216, 137, .05)
}

.home5-process-area .achievement-text-slider .marquee_text2 .js-marquee:last-child {
    display: none;
    visibility: hidden
}

.home5-process-area .single-process {
    transition: .5s
}

.home5-process-area .single-process .sl {
    margin-bottom: 17px;
    transition: .5s
}

.home5-process-area .single-process .sl h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 60px;
    letter-spacing: .03em;
    margin-bottom: 0;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, .12);
    transition: .5s
}

.home5-process-area .single-process .content h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 10px;
    transition: .5s
}

@media(max-width:1199px) {
    .home5-process-area .single-process .content h3 {
        font-size: 28px
    }
}

.home5-process-area .single-process .content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.home5-process-area .single-process:hover .sl h2 {
    -webkit-text-stroke: 1px rgba(6, 216, 137, .2)
}

.home5-process-area .single-process:hover .content h3 {
    color: var(--theme-color)
}

.home5-process-area .achievement-area {
    padding-top: 105px
}

@media(max-width:991px) {
    .home5-process-area .achievement-area {
        padding-top: 80px
    }
}

.home5-process-area .achievement-area .achievement-content {
    max-width: 560px;
    width: 100%;
    padding-left: 50px
}

@media(max-width:1199px) {
    .home5-process-area .achievement-area .achievement-content {
        padding-left: 0
    }
}

.home5-process-area .achievement-area .achievement-content h2 {
    margin-bottom: 5px;
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    color: var(--theme-color)
}

.home5-process-area .achievement-area .achievement-content h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.3;
    letter-spacing: .02em;
    color: var(--white-color)
}

@media(max-width:576px) {
    .home5-process-area .achievement-area .achievement-content h3 {
        font-size: 24px
    }
}

.home5-process-area .achievement-area .achievement-content h3 span {
    color: var(--theme-color);
    font-size: 33px
}

@media(max-width:576px) {
    .home5-process-area .achievement-area .achievement-content h3 span {
        font-size: 27px
    }
}

.home5-process-area .achievement-area .achievement-content h3 span.year {
    position: relative;
    padding-left: 8px;
    padding-right: 8px
}

.home5-process-area .achievement-area .achievement-content h3 span.year::after {
    content: "";
    background-color: rgba(6, 216, 137, .15);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 40px
}

.home5-process-area .achievement-area .achievement-img {
    border-radius: 250px;
    position: relative
}

@media(max-width:991px) {
    .home5-process-area .achievement-area .achievement-img {
        display: inline-block
    }
}

.home5-process-area .achievement-area .achievement-img::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 250px;
    background: linear-gradient(180deg, rgba(29, 29, 29, 0.3) 0%, rgba(29, 29, 29, 0.6) 100%)
}

.home5-process-area .achievement-area .achievement-img img {
    border-radius: 250px
}

.home5-partner-area {
    background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-partner-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

@media(max-width:991px) {
    .home5-partner-area {
        padding: 80px 0
    }
}

.home5-partner-area .partner-wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 6;
    columns: 6;
    gap: 0
}

@media(max-width:991px) {
    .home5-partner-area .partner-wrap ul {
        -moz-columns: 4;
        columns: 4
    }
}

@media(max-width:767px) {
    .home5-partner-area .partner-wrap ul {
        -moz-columns: 3;
        columns: 3
    }
}

@media(max-width:576px) {
    .home5-partner-area .partner-wrap ul {
        -moz-columns: 2;
        columns: 2
    }
}

.home5-partner-area .partner-wrap ul li {
    border: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 33px 10px;
    transition: .5s
}

.home5-partner-area .partner-wrap ul li:hover {
    background: rgba(255, 255, 255, .1)
}

@media(max-width:1399px) {
    .home5-partner-area .partner-wrap ul li {
        padding: 20px 0
    }
}

@media(max-width:1199px) {
    .home5-partner-area .partner-wrap ul li {
        padding: 12px 0
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-case-study {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-case-study .primary-btn3 {
    background-color: transparent;
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    border-radius: 0
}

.home5-case-study .primary-btn3::after {
    border-radius: 0;
    background-color: var(--theme-color)
}

.home5-case-study .primary-btn3:hover {
    color: var(--white-color)
}

.home5-case-study .single-case-study {
    border-top: 1px solid rgba(255, 255, 255, .2);
    padding: 50px 0
}

@media(max-width:991px) {
    .home5-case-study .single-case-study {
        padding: 40px 0 50px
    }
}

@media(max-width:576px) {
    .home5-case-study .single-case-study {
        padding: 30px 0 40px
    }
}

.home5-case-study .single-case-study:last-child {
    padding-bottom: 0
}

.home5-case-study .single-case-study .case-study-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 10px
}

@media(max-width:1199px) {
    .home5-case-study .single-case-study .case-study-content span {
        font-size: 15px
    }
}

.home5-case-study .single-case-study .case-study-content h3 {
    margin-bottom: 0
}

.home5-case-study .single-case-study .case-study-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s
}

.home5-case-study .single-case-study .case-study-content h3 a:hover {
    color: var(--theme-color)
}

.home5-case-study .single-case-study .case-study-img-and-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px
}

@media(max-width:576px) {
    .home5-case-study .single-case-study .case-study-img-and-btn {
        flex-wrap: wrap;
        gap: 30px
    }
}

.home5-case-study .single-case-study .case-study-img-and-btn .case-img {
    border-radius: 5px
}

.home5-case-study .single-case-study .case-study-img-and-btn .case-img img {
    border-radius: 5px
}

.home5-case-study .single-case-study .case-study-img-and-btn .primary-btn8 svg {
    margin-left: 0;
    margin-right: 7px;
    transform: rotate(0);
    transition: .5s
}

.home5-case-study .single-case-study .case-study-img-and-btn .primary-btn8:hover svg {
    transform: rotate(45deg)
}

.home5-testimonial-area {
    background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-testimonial-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

@media(max-width:991px) {
    .home5-testimonial-area {
        padding: 80px 0
    }
}

.home5-testimonial-area .testimolial-left p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding-top: 20px
}

.home5-testimonial-area .testimolial-left .customar-review {
    padding-top: 45px
}

.home5-testimonial-area .testimolial-left .customar-review h6 {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    color: var(--white-color);
    margin-bottom: 12px
}

.home5-testimonial-area .testimolial-left .customar-review>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px
}

@media(max-width:1199px) {
    .home5-testimonial-area .testimolial-left .customar-review>ul {
        gap: 20px
    }
}

.home5-testimonial-area .testimolial-left .customar-review>ul li .single-review {
    padding: 15px 25px;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 10px;
    max-width: 168px;
    width: 100%;
    display: block
}

@media(max-width:576px) {
    .home5-testimonial-area .testimolial-left .customar-review>ul li .single-review {
        padding: 7px 10px
    }
}

.home5-testimonial-area .testimolial-left .customar-review>ul li .single-review .icon {
    margin-bottom: 8px
}

.home5-testimonial-area .testimolial-left .customar-review>ul li .single-review .star {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: baseline;
    gap: 4px
}

.home5-testimonial-area .testimolial-left .customar-review>ul li .single-review .star li {
    line-height: 1;
    font-size: 14px;
    color: #fff
}

.home5-testimonial-area .testimolial-left .customar-review>ul li .single-review .star li i {
    font-size: 10px;
    color: #f9bf00
}

.home5-testimonial-area .testimonial-wrapper {
    position: relative;
    padding: 30px
}

@media(max-width:767px) {
    .home5-testimonial-area .testimonial-wrapper {
        padding: 0
    }
}

.home5-testimonial-area .testimonial-wrapper .testimonial-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px
}

@media(max-width:767px) {
    .home5-testimonial-area .testimonial-wrapper .testimonial-top {
        flex-wrap: wrap;
        gap: 15px
    }
}

.home5-testimonial-area .testimonial-wrapper .testimonial-top .author-img {
    height: 90px;
    width: 90px;
    border-radius: 50%
}

.home5-testimonial-area .testimonial-wrapper .testimonial-top .author-img img {
    height: 90px;
    width: 90px;
    border-radius: 50%
}

.home5-testimonial-area .testimonial-wrapper .testimonial-content {
    padding-top: 33px;
    margin-bottom: 38px
}

.home5-testimonial-area .testimonial-wrapper .testimonial-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 20px;
    line-height: 42px;
    text-transform: capitalize;
    color: #e4e4e4
}

@media(max-width:576px) {
    .home5-testimonial-area .testimonial-wrapper .testimonial-content p {
        font-size: 18px;
        line-height: 35px
    }
}

.home5-testimonial-area .testimonial-wrapper .testimonial-btm .author-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 24px;
    color: #e4e4e4;
    margin-bottom: 3px
}

.home5-testimonial-area .testimonial-wrapper .testimonial-btm .author-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: .02em;
    color: var(--theme-color)
}

.home5-testimonial-area .testimonial-wrapper .testimonial-btm .quote-icon {
    position: absolute;
    right: 50px;
    bottom: 20px
}

.home5-testimonial-area .swiper-btn-group {
    flex-direction: column;
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-50%)
}

@media(max-width:1500px) {
    .home5-testimonial-area .swiper-btn-group {
        right: 0
    }
}

@media(max-width:767px) {
    .home5-testimonial-area .swiper-btn-group {
        display: none;
        visibility: hidden
    }
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-award-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-award-area .award-wrapper table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table thead {
        display: none;
        visibility: hidden
    }
}

.home5-award-area .award-wrapper table thead tr {
    border-bottom: 1px solid rgba(255, 255, 255, .1)
}

.home5-award-area .award-wrapper table thead tr td {
    padding: 35px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--theme-color)
}

@media(max-width:991px) {
    .home5-award-area .award-wrapper table thead tr td {
        padding: 35px 15px
    }
}

.home5-award-area .award-wrapper table thead tr td:first-child {
    width: 45%
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table thead tr td:first-child {
        width: 55%
    }
}

.home5-award-area .award-wrapper table thead tr td:nth-child(2) {
    text-align: center;
    width: 20%
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table thead tr td:nth-child(2) {
        width: 25%
    }
}

.home5-award-area .award-wrapper table thead tr td:last-child {
    text-align: end;
    width: 35%
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table thead tr td:last-child {
        width: 25%
    }
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody {
        display: block;
        width: 100%
    }
}

.home5-award-area .award-wrapper table tbody tr {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    transition: .5s
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr {
        display: block;
        width: 100%
    }
}

.home5-award-area .award-wrapper table tbody tr td {
    padding: 32px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: rgba(224, 224, 224, .7);
    position: relative;
    transition: .5s
}

@media(max-width:991px) {
    .home5-award-area .award-wrapper table tbody tr td {
        padding: 35px 15px
    }
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr td {
        padding: 15px 0
    }
}

.home5-award-area .award-wrapper table tbody tr td img {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: scaleY(0);
    transition: .5s;
    transform-origin: bottom
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr td img {
        display: none;
        visibility: hidden
    }
}

.home5-award-area .award-wrapper table tbody tr td:first-child {
    width: 45%;
    font-weight: 500;
    color: #fff
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table tbody tr td:first-child {
        width: 55%
    }
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr td:first-child {
        text-align: end
    }
}

.home5-award-area .award-wrapper table tbody tr td:nth-child(2) {
    text-align: center;
    width: 20%
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table tbody tr td:nth-child(2) {
        width: 25%
    }
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr td:nth-child(2) {
        text-align: end
    }
}

.home5-award-area .award-wrapper table tbody tr td:last-child {
    text-align: end;
    width: 35%
}

@media(max-width:1199px) {
    .home5-award-area .award-wrapper table tbody tr td:last-child {
        width: 20%
    }
}

@media(max-width:767px) {
    .home5-award-area .award-wrapper table tbody tr td {
        width: 100% !important;
        display: block;
        padding-left: 30%;
        font-size: 16px
    }

    .home5-award-area .award-wrapper table tbody tr td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 30%;
        text-align: left;
        font-weight: 600;
        color: #fff;
        font-size: 16px
    }
}

.home5-award-area .award-wrapper table tbody tr:hover td {
    color: #e0e0e0
}

.home5-award-area .award-wrapper table tbody tr:hover td img {
    opacity: 1;
    transform: scaleY(1)
}

.collaborate-section {
    background-image: linear-gradient(90deg, #171717 8.12%, #171717 40.79%, rgba(23, 23, 23, 0.96) 49.45%, rgba(23, 23, 23, 0.94) 55.48%, rgba(23, 23, 23, 0.1) 99.09%), url(../img/home-5/collaborate-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 135px 100px
}

@media(max-width:767px) {
    .collaborate-section {
        padding: 100px 0
    }
}

.collaborate-section .get-btn {
    padding-top: 45px
}

.collaborate-section .get-btn a {
    border-radius: unset;
    font-size: 18px;
    padding: 14px 40px
}

.collaborate-section .get-btn a::after {
    border-radius: unset
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-team-section {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-team-section .single-team {
    background: #121212;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 200px 200px 0 0;
    padding: 20px 20px 30px;
    position: relative;
    transition: .5s
}

@media(max-width:1199px) {
    .home5-team-section .single-team {
        padding: 20px 15px 30px
    }
}

.home5-team-section .single-team .social-area {
    border: 1px solid rgba(255, 255, 255, .2);
    max-width: 42px;
    width: 100%;
    padding: 25px 0;
    position: absolute;
    left: -42px;
    bottom: 50px;
    transition: .5s;
    opacity: 0;
    transform: scaleX(0);
    transform-origin: right;
    background-color: #121212;
    z-index: 99999
}

@media(max-width:991px) {
    .home5-team-section .single-team .social-area {
        left: 0;
        transform-origin: left
    }
}

.home5-team-section .single-team .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px
}

.home5-team-section .single-team .social-area ul li {
    position: relative
}

.home5-team-section .single-team .social-area ul li::after {
    content: "";
    height: 2.6px;
    width: 2.6px;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%)
}

.home5-team-section .single-team .social-area ul li:last-child::after {
    display: none;
    visibility: hidden
}

.home5-team-section .single-team .social-area ul li a {
    color: rgba(255, 255, 255, .7);
    transition: .5s
}

.home5-team-section .single-team .social-area ul li a:hover {
    color: var(--white-color)
}

.home5-team-section .single-team .team-img {
    border-radius: 200px 200px 0 0;
    margin-bottom: 30px;
    transition: .5s
}

.home5-team-section .single-team .team-img img {
    border-radius: 200px 200px 0 0;
    transition: .5s
}

@media(max-width:991px) {
    .home5-team-section .single-team .team-img img {
        width: 100%
    }
}

.home5-team-section .single-team .team-content {
    text-align: center
}

.home5-team-section .single-team .team-content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: var(--white-color);
    margin-bottom: 2px
}

@media(max-width:576px) {
    .home5-team-section .single-team .team-content h4 {
        font-size: 22px
    }
}

.home5-team-section .single-team .team-content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    color: rgba(255, 255, 255, .5);
    line-height: 1
}

.home5-team-section .single-team:hover {
    border-radius: 0
}

.home5-team-section .single-team:hover .social-area {
    opacity: 1;
    transform: scaleX(1)
}

.home5-team-section .single-team:hover .team-img {
    border-radius: 0
}

.home5-team-section .single-team:hover .team-img img {
    border-radius: 0
}

@media(min-width:1400px) and (max-width:1599px) {
    .home5-blog-area {
        padding-left: 70px;
        padding-right: 70px
    }
}

.home5-blog-area .blog-wrapper .swiper-slide::after {
    content: "";
    position: absolute;
    top: 0;
    right: -25px;
    width: 1px;
    height: 100%;
    background-color: rgba(217, 217, 217, .15)
}

.home5-blog-area .blog-wrapper .single-blog-card {
    padding: 60px 0;
    transition: .5s
}

@media(max-width:1500px) {
    .home5-blog-area .blog-wrapper .single-blog-card {
        padding: 45px 0
    }
}

@media(max-width:1199px) {
    .home5-blog-area .blog-wrapper .single-blog-card {
        padding: 25px 0
    }
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content ul li a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    color: #b5b5b5
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content ul li:last-child a {
    color: var(--theme-color)
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content h3 {
    margin-bottom: 10px
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 27px;
    line-height: 1.45;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s
}

@media(max-width:1500px) {
    .home5-blog-area .blog-wrapper .single-blog-card .blog-content h3 a {
        font-size: 24px
    }
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content h3 a:hover {
    color: var(--theme-color)
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 36px
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-img {
    border: .5px solid rgba(255, 255, 255, .15);
    padding: 5px;
    transition: .5s
}

.home5-blog-area .blog-wrapper .single-blog-card .blog-img img {
    width: 100%
}

.home5-blog-area .blog-wrapper .single-blog-card:hover .blog-img {
    padding: 0
}

footer {
    background-image: url(../img/footer/footer-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden
}

footer.two {
    background-image: url(../img/footer/footer-bg.png), linear-gradient(#1d2125, #1d2125)
}

@media(max-width:767px) {
    footer.two {
        background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
}

footer.three {
    background-image: url(../img/footer/footer-bg.png), linear-gradient(#171717, #171717)
}

@media(min-width:1400px) and (max-width:1599px) {
    footer.three {
        padding-left: 70px;
        padding-right: 70px
    }
}

@media(max-width:767px) {
    footer.three {
        background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
}

footer.four {
    background-image: url(../img/footer/footer-bg.png), linear-gradient(#1e1c22, #1e1c22)
}

@media(max-width:767px) {
    footer.four {
        background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
}

@media(max-width:767px) {
    footer {
        background-image: url(../img/home-5/home5-services-bg.png), linear-gradient(#171717, #171717)
    }
}

footer .footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    margin-bottom: 80px
}

footer .footer-top .footer-top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0
}

@media(max-width:576px) {
    footer .footer-top .footer-top-content {
        flex-wrap: wrap;
        gap: 20px
    }
}

footer .footer-top .footer-top-content .footer-contect {
    display: flex;
    align-items: center;
    gap: 10px
}

footer .footer-top .footer-top-content .footer-contect .icon svg {
    fill: var(--theme-color)
}

footer .footer-top .footer-top-content .footer-contect .content span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 13px;
    letter-spacing: .03em;
    color: var(--white-color);
    display: inline-block;
    margin-bottom: 3px
}

footer .footer-top .footer-top-content .footer-contect .content h6 {
    margin-bottom: 0
}

footer .footer-top .footer-top-content .footer-contect .content h6 a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 18px;
    letter-spacing: .03em;
    color: var(--white-color);
    transition: .5s
}

footer .footer-top .footer-top-content .footer-contect .content h6 a:hover {
    color: var(--theme-color)
}

footer .footer-widget .footer-contact {
    max-width: 300px;
    width: 100%;
    margin: 0 auto
}

footer .footer-widget .footer-contact h4 {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 23px;
    letter-spacing: .03em;
    color: var(--white-color)
}

footer .footer-widget .footer-contact h4 svg {
    fill: var(--theme-color)
}

footer .footer-widget .footer-contact h6 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .02em;
    color: var(--white-color);
    margin-bottom: 20px
}

footer .footer-widget .footer-contact a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    transition: .5s
}

footer .footer-widget .footer-contact a:hover {
    color: var(--theme-color)
}

footer .footer-widget .footer-contact .form-inner {
    display: flex;
    align-items: center
}

footer .footer-widget .footer-contact .form-inner input {
    width: 100%;
    background: rgba(27, 31, 35, .8);
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 5px 0 0 5px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 13px;
    color: #a4a4a4;
    height: 50px;
    padding: 25px 20px
}

footer .footer-widget .footer-contact .form-inner button {
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: var(--theme-color);
    border-radius: 0 5px 5px 0;
    transition: .5s
}

footer .footer-widget .footer-contact .form-inner button svg {
    stroke: #1d1d1d;
    transition: .5s
}

footer .footer-widget .footer-contact .form-inner button:hover {
    background-color: var(--white-color)
}

footer .footer-widget .widget-title {
    margin-bottom: 25px
}

footer .footer-widget .widget-title h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    letter-spacing: .03em;
    color: var(--white-color);
    margin-bottom: 0
}

footer .footer-widget .menu-container ul {
    margin: 0;
    padding: 0;
    list-style: none
}

footer .footer-widget .menu-container ul li {
    margin-bottom: 18px
}

footer .footer-widget .menu-container ul li:last-child {
    margin-bottom: 0
}

footer .footer-widget .menu-container ul li a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .02em;
    color: #b4b4b4;
    transition: .5s
}

footer .footer-widget .menu-container ul li a:hover {
    color: var(--theme-color)
}

footer .footer-btm {
    border-top: 1px solid rgba(255, 255, 255, .08);
    margin-top: 80px
}

footer .footer-btm .footer-btn-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0
}

@media(max-width:767px) {
    footer .footer-btm .footer-btn-content {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center
    }
}

footer .footer-btm .footer-btn-content .copyright-area p {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    color: #e4e4e4
}

@media(max-width:767px) {
    footer .footer-btm .footer-btn-content .copyright-area p {
        text-align: center
    }
}

footer .footer-btm .footer-btn-content .copyright-area p a {
    color: var(--white-color);
    font-weight: 700;
    transition: .5s
}

footer .footer-btm .footer-btn-content .copyright-area p a:hover {
    color: var(--theme-color)
}

footer .footer-btm .footer-btn-content .footer-social ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 28px
}

footer .footer-btm .footer-btn-content .footer-social ul li a {
    height: 28px;
    width: 28px;
    border-radius: 4px;
    background-color: rgba(15, 16, 18, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    transition: .5s
}

footer .footer-btm .footer-btn-content .footer-social ul li a:hover {
    color: var(--white-color);
    background-color: var(--theme-color)
}

.bg-6 {
    background: #161519
}

.review-and-counter-area {
    display: flex;
    align-items: center
}

@media(max-width:1199px) {
    .review-and-counter-area {
        flex-direction: column
    }
}

.review-and-counter-area .customar-review {
    background-image: url(../img/home-6/home6-client-review-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 545px;
    padding: 50px 85px
}

@media(max-width:1399px) {
    .review-and-counter-area .customar-review {
        min-width: unset;
        max-width: 450px;
        width: 100%;
        padding: 70px 35px
    }
}

@media(max-width:1199px) {
    .review-and-counter-area .customar-review {
        max-width: 100%
    }
}

@media(max-width:576px) {
    .review-and-counter-area .customar-review {
        padding: 70px 10px
    }
}

.review-and-counter-area .customar-review h6 {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    color: var(--white-color);
    margin-bottom: 12px
}

.review-and-counter-area .customar-review>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 2;
    columns: 2
}

@media(max-width:1199px) {
    .review-and-counter-area .customar-review>ul {
        -moz-columns: unset;
        columns: unset;
        display: flex;
        gap: 20px
    }
}

@media(max-width:576px) {
    .review-and-counter-area .customar-review>ul {
        flex-wrap: wrap;
        justify-self: center
    }
}

@media(max-width:1199px) {
    .review-and-counter-area .customar-review>ul>li:last-child {
        margin-bottom: 0
    }
}

@media(max-width:576px) {
    .review-and-counter-area .customar-review>ul>li {
        display: flex;
        justify-content: center
    }
}

.review-and-counter-area .customar-review>ul>li a {
    padding: 15px 25px;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 10px;
    max-width: 168px;
    width: 100%;
    display: block
}

@media(max-width:576px) {
    .review-and-counter-area .customar-review>ul>li a {
        padding: 10px
    }
}

.review-and-counter-area .customar-review>ul>li a .icon {
    margin-bottom: 8px
}

.review-and-counter-area .customar-review>ul>li a .star {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: baseline;
    gap: 4px
}

.review-and-counter-area .customar-review>ul>li a .star li {
    line-height: 1;
    font-size: 14px;
    color: #fff
}

.review-and-counter-area .customar-review>ul>li a .star li i {
    font-size: 10px;
    color: #f9bf00
}

.review-and-counter-area .counter-area {
    padding: 69px 30px;
    width: 100%
}

@media(max-width:1399px) {
    .review-and-counter-area .counter-area {
        padding: 69px 10px
    }
}

@media(max-width:1199px) {
    .review-and-counter-area .counter-area {
        padding: 0 10px
    }
}

.review-and-counter-area .counter-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 4;
    columns: 4;
    gap: 0
}

@media(max-width:767px) {
    .review-and-counter-area .counter-area ul {
        -moz-columns: 2;
        columns: 2
    }
}

@media(max-width:576px) {
    .review-and-counter-area .counter-area ul {
        -moz-columns: 1;
        columns: 1
    }
}

.review-and-counter-area .counter-area ul li {
    display: flex;
    justify-content: center;
    position: relative
}

@media(max-width:767px) {
    .review-and-counter-area .counter-area ul li {
        margin-bottom: 30px
    }

    .review-and-counter-area .counter-area ul li:nth-child(even) {
        margin-bottom: 0
    }
}

@media(max-width:576px) {
    .review-and-counter-area .counter-area ul li:nth-child(even) {
        margin-bottom: 30px
    }

    .review-and-counter-area .counter-area ul li:last-child {
        margin-bottom: 0
    }
}

.review-and-counter-area .counter-area ul li::after {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .1)
}

@media(max-width:767px) {
    .review-and-counter-area .counter-area ul li::after {
        display: none;
        visibility: hidden
    }
}

.review-and-counter-area .counter-area ul li:last-child::after {
    display: none;
    visibility: hidden
}

@media(max-width:576px) {
    .review-and-counter-area .counter-area .single-counter {
        text-align: center
    }
}

.review-and-counter-area .counter-area .single-counter .number {
    display: flex;
    align-items: baseline
}

@media(max-width:576px) {
    .review-and-counter-area .counter-area .single-counter .number {
        text-align: center;
        justify-content: center
    }
}

.review-and-counter-area .counter-area .single-counter .number h3 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 38px;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:1399px) {
    .review-and-counter-area .counter-area .single-counter .number h3 {
        font-size: 32px
    }
}

.review-and-counter-area .counter-area .single-counter .number span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--white-color);
    display: inline-block;
    margin-left: 5px
}

@media(max-width:1399px) {
    .review-and-counter-area .counter-area .single-counter .number span {
        font-size: 18px
    }
}

.review-and-counter-area .counter-area .single-counter p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #b5b5b5;
    padding-top: 3px
}

@media(max-width:576px) {
    .review-and-counter-area .counter-area .single-counter p {
        text-align: center
    }
}

.home6-solution-section {
    background: #1e1c22;
    padding: 100px 0
}

@media(max-width:991px) {
    .home6-solution-section {
        padding: 80px 0
    }
}

.home6-solution-section .single-solution {
    position: relative;
    border-radius: 5px;
    z-index: 1;
    padding: 30px 30px 50px;
    overflow: hidden;
    transition: .5s
}

.home6-solution-section .single-solution::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/home-6/home6-solution-bg1.png), linear-gradient(#161519, #161519);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    transition: .5s
}

.home6-solution-section .single-solution .background-img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: -2;
    transition: .5s;
    opacity: 0
}

.home6-solution-section .single-solution .background-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.home6-solution-section .single-solution .background-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 24, 28, .75);
    transition: .5s
}

.home6-solution-section .single-solution .sl {
    margin-bottom: 15px
}

.home6-solution-section .single-solution .sl h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 50px;
    letter-spacing: .03em;
    color: rgba(255, 255, 255, .08);
    margin-bottom: 0
}

.home6-solution-section .single-solution .solution-content h3 {
    margin-bottom: 13px
}

.home6-solution-section .single-solution .solution-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 27px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #fff
}

.home6-solution-section .single-solution .solution-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 30px
}

.home6-solution-section .single-solution .solution-btn-icon {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.home6-solution-section .single-solution .icon {
    position: absolute;
    right: 30px;
    bottom: 20px;
    transition: .5s;
    transform: scale(1);
    transform-origin: right bottom
}

.home6-solution-section .single-solution .icon svg {
    fill: var(--theme-color)
}

.home6-solution-section .single-solution .icon .blure {
    position: absolute
}

.home6-solution-section .single-solution .icon::after {
    content: "";
    background-color: rgba(6, 216, 137, .1);
    position: absolute;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    bottom: -78px;
    right: -55px
}

.home6-solution-section .single-solution:hover::after {
    opacity: 0
}

.home6-solution-section .single-solution:hover .background-img {
    opacity: 1
}

.home6-solution-section .single-solution:hover .icon {
    opacity: 0;
    transform: scale(0)
}

.home6-solution-section .single-solution:hover .sl h2 {
    color: rgba(255, 255, 255, .2)
}

.home6-solution-section .single-solution:hover .solution-content h3 a {
    color: #fff
}

.home6-solution-section .single-solution:hover .solution-content p {
    color: rgba(255, 255, 255, .7)
}

.home6-solution-section .swiper-pagination61 {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    gap: 15px
}

@media(max-width:767px) {
    .home6-solution-section .swiper-pagination61 {
        display: none;
        visibility: hidden
    }
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet {
    height: 14px;
    width: 14px;
    background-color: transparent;
    border: 1px solid var(--theme-color);
    opacity: 1;
    position: relative
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet::after {
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--theme-color);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet-active {
    border-color: var(--theme-color)
}

.home6-solution-section .swiper-pagination61 .swiper-pagination-bullet-active::after {
    opacity: 1
}

.home6-choose-section .choose-top .choose-title {
    padding-top: 80px;
    max-width: 450px;
    width: 100%;
    margin-left: 50px
}

@media(max-width:991px) {
    .home6-choose-section .choose-top .choose-title {
        padding-top: 0
    }
}

@media(max-width:767px) {
    .home6-choose-section .choose-top .choose-title {
        margin-left: 0
    }
}

.home6-choose-section .choose-top .choose-title span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px
}

.home6-choose-section .choose-top .choose-title span::after {
    content: "";
    height: 1px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--theme-color)
}

.home6-choose-section .choose-top .choose-title h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 45px;
    line-height: 1.3;
    text-transform: capitalize;
    color: #fff
}

@media(max-width:1199px) {
    .home6-choose-section .choose-top .choose-title h2 {
        font-size: 35px
    }
}

@media(max-width:576px) {
    .home6-choose-section .choose-top .choose-title h2 {
        font-size: 30px
    }
}

.home6-choose-section .choose-top .choose-right-img {
    position: relative;
    z-index: 1
}

@media(max-width:991px) {
    .home6-choose-section .choose-top .choose-right-img img {
        width: 100%
    }
}

.home6-choose-section .choose-top .choose-right-img::after {
    content: "";
    background-color: rgba(30, 28, 34, .5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.home6-choose-section .choose-btm {
    padding-top: 50px
}

.home6-choose-section .choose-btm .choose-left-content {
    background-image: url(../img/home-6/choose-bg-1.png), linear-gradient(#1e1c22, #1e1c22);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 65px 75px 65px 85px;
    position: relative;
    margin-right: 15px;
    margin-top: -120px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content {
        margin-top: 0;
        padding: 65px 30px
    }
}

@media(max-width:991px) {
    .home6-choose-section .choose-btm .choose-left-content {
        margin-right: 0
    }
}

.home6-choose-section .choose-btm .choose-left-content .icon {
    margin-bottom: 22px
}

.home6-choose-section .choose-btm .choose-left-content h4 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 24px;
    line-height: 1.46;
    letter-spacing: .03em;
    color: #e4e4e4;
    margin-bottom: 25px
}

.home6-choose-section .choose-btm .choose-left-content h4 span {
    color: var(--theme-color);
    font-size: 26px;
    font-weight: 600
}

@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content h4 {
        font-size: 22px
    }

    .home6-choose-section .choose-btm .choose-left-content h4 span {
        font-size: 24px
    }
}

.home6-choose-section .choose-btm .choose-left-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding-left: 60px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content p {
        padding-left: 30px
    }
}

@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content p {
        padding-left: 10px
    }
}

.home6-choose-section .choose-btm .choose-left-content .sl {
    padding-top: 65px;
    padding-left: 60px
}

@media(max-width:1199px) {
    .home6-choose-section .choose-btm .choose-left-content .sl {
        padding-left: 30px
    }
}

@media(max-width:576px) {
    .home6-choose-section .choose-btm .choose-left-content .sl {
        padding-left: 10px
    }
}

.home6-choose-section .choose-btm .choose-left-content .sl h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 50px;
    line-height: 1;
    letter-spacing: .05em;
    color: rgba(228, 228, 228, .3)
}

.home6-choose-section .choose-btm .choose-left-content .about-btn {
    position: absolute;
    right: 30px;
    bottom: 20px
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a {
    width: 123px;
    height: 123px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    gap: 5px;
    transition: .7s
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a svg {
    stroke: var(--white-color);
    transition: .7s
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a:hover {
    border-color: var(--theme-color);
    box-shadow: inset 0 0 0 10em var(--theme-color)
}

.home6-choose-section .choose-btm .choose-left-content .about-btn a:hover svg {
    transform: rotate(45deg)
}

.home6-choose-section .choose-btm .choose-left-content .choose-vec-top-r {
    position: absolute;
    bottom: 0;
    left: 0
}

.home6-choose-section .choose-btm .choose-left-content .choose-vec-btm-l {
    position: absolute;
    right: 0;
    top: 0
}

.home6-choose-section .choose-btm .choose-feature ul li {
    margin-bottom: 40px
}

.home6-choose-section .choose-btm .choose-feature ul li:last-child {
    margin-bottom: 0
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature {
    display: flex;
    align-items: center;
    gap: 25px
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .progress {
    height: 100px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, .2)
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .progress h3 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 30px;
    text-transform: capitalize;
    color: var(--white-color)
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content {
    max-width: 290px;
    width: 100%
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 8px
}

.home6-choose-section .choose-btm .choose-feature ul li .single-feature .content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.home6-case-study-section .border-rights {
    position: relative
}

.home6-case-study-section .border-rights::after {
    content: "";
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, .1);
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%)
}

@media(max-width:991px) {
    .home6-case-study-section .border-rights::after {
        display: none;
        visibility: hidden
    }
}

.home6-case-study-section .border-bottom1 {
    position: relative
}

.home6-case-study-section .border-bottom1::after {
    content: "";
    width: 114%;
    height: 1px;
    background-color: rgba(255, 255, 255, .1);
    position: absolute;
    left: 0;
    bottom: 0
}

@media(max-width:1399px) {
    .home6-case-study-section .border-bottom1::after {
        width: 110%
    }
}

@media(max-width:1199px) {
    .home6-case-study-section .border-bottom1::after {
        width: 107%
    }
}

@media(max-width:991px) {
    .home6-case-study-section .border-bottom1::after {
        display: none;
        visibility: hidden
    }
}

.home6-case-study-section .border-bottom2 {
    position: relative
}

.home6-case-study-section .border-bottom2::after {
    content: "";
    width: 114%;
    height: 1px;
    background-color: rgba(255, 255, 255, .1);
    position: absolute;
    right: 0;
    bottom: 0
}

@media(max-width:1399px) {
    .home6-case-study-section .border-bottom2::after {
        width: 110%
    }
}

@media(max-width:1199px) {
    .home6-case-study-section .border-bottom2::after {
        width: 107%
    }
}

@media(max-width:991px) {
    .home6-case-study-section .border-bottom2::after {
        display: none;
        visibility: hidden
    }
}

.home6-case-study-section .case-right {
    padding-left: 80px;
    padding-top: 100px
}

@media(max-width:1399px) {
    .home6-case-study-section .case-right {
        padding-left: 50px
    }
}

@media(max-width:1199px) {
    .home6-case-study-section .case-right {
        padding-left: 30px
    }
}

@media(max-width:991px) {
    .home6-case-study-section .case-right {
        padding-left: 0;
        padding-top: 0
    }
}

.home6-case-study-section .case-left {
    padding-right: 80px
}

@media(max-width:1399px) {
    .home6-case-study-section .case-left {
        padding-right: 50px
    }
}

@media(max-width:1199px) {
    .home6-case-study-section .case-left {
        padding-right: 30px
    }
}

@media(max-width:991px) {
    .home6-case-study-section .case-left {
        padding-right: 0
    }
}

.home6-case-study-section .pt-50 {
    padding-top: 50px
}

@media(max-width:991px) {
    .home6-case-study-section .pt-50 {
        padding-top: 0
    }
}

.home6-case-study-section .pb-50 {
    padding-bottom: 50px !important
}

@media(max-width:991px) {
    .home6-case-study-section .pb-50 {
        padding-bottom: 0 !important
    }
}

.home6-case-study-section .home6-case-study .case-img {
    margin-bottom: 12px
}

.home6-case-study-section .home6-case-study .case-content {
    padding: 15px 15px 0
}

@media(max-width:576px) {
    .home6-case-study-section .home6-case-study .case-content {
        padding: 15p 0
    }
}

.home6-case-study-section .home6-case-study .case-content>span {
    font-family: var(--theme-color);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 5px
}

.home6-case-study-section .home6-case-study .case-content h3 {
    margin-bottom: 15px
}

.home6-case-study-section .home6-case-study .case-content h3 a {
    font-family: var(--theme-color);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.4;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s
}

@media(max-width:576px) {
    .home6-case-study-section .home6-case-study .case-content h3 a {
        font-size: 24px
    }
}

.home6-case-study-section .home6-case-study .case-content h3 a:hover {
    color: var(--theme-color)
}

.home6-case-study-section .home6-case-study .case-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 20px
}

.home6-case-study-section .view-btn {
    padding-top: 80px
}

.home6-case-study-section .view-btn .primary-btn3 {
    border-radius: 0;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: .03em;
    padding: 16px 46px
}

.home6-case-study-section .view-btn .primary-btn3::after {
    border-radius: 0
}

.home6-partner-area {
    overflow: hidden
}

.home6-partner-area .section-title-6 {
    height: 70px
}

.home6-partner-area .section-title-6 h5 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 22px;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: var(--white-color)
}

.home6-partner-area .section-title-6 span {
    font-size: 80px
}

.home6-partner-area .partner-logo {
    opacity: .3;
    transition: .5s;
    display: flex;
    justify-content: center;
    max-width: 150px;
    border: 1px solid #414141;
    padding: 15px
}

.home6-partner-area .partner-logo:hover {
    opacity: 1
}

.home6-partner-area .marque-wrap {
    overflow: hidden
}

.home6-partner-area .js-marquee-wrapper {
    display: flex;
    align-items: center;
    gap: 25px
}

.home6-partner-area .js-marquee-wrapper .js-marquee {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-right: 0 !important
}

.home6-testimonial-area {
    background-image: url(../img/home-6/home6-testimonial-bg.png), linear-gradient(#1E1C22, #1E1C22);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 0;
    position: relative
}

@media(max-width:991px) {
    .home6-testimonial-area {
        padding: 80px 0
    }
}

.home6-testimonial-area .sm-img-wrap {
    margin: 0;
    padding: 0;
    list-style: none
}

.home6-testimonial-area .sm-img-wrap li {
    position: absolute;
    opacity: .3
}

.home6-testimonial-area .sm-img-wrap li:nth-child(1) {
    right: 260px;
    top: 120px
}

@media(max-width:1199px) {
    .home6-testimonial-area .sm-img-wrap li:nth-child(1) {
        top: 200px;
        right: 80px
    }
}

@media(max-width:767px) {
    .home6-testimonial-area .sm-img-wrap li:nth-child(1) {
        display: none;
        visibility: hidden
    }
}

.home6-testimonial-area .sm-img-wrap li:nth-child(1) img {
    height: 90px;
    width: 90px;
    border-radius: 50%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(2) {
    left: 120px;
    top: 220px
}

@media(max-width:1199px) {
    .home6-testimonial-area .sm-img-wrap li:nth-child(2) {
        top: 170px;
        left: 80px
    }
}

@media(max-width:767px) {
    .home6-testimonial-area .sm-img-wrap li:nth-child(2) {
        display: none;
        visibility: hidden
    }
}

.home6-testimonial-area .sm-img-wrap li:nth-child(2) img {
    height: 90px;
    width: 90px;
    border-radius: 50%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(3) {
    left: 22%;
    top: 40%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(3) img {
    height: 42px;
    width: 42px;
    border-radius: 50%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(4) {
    right: 22%;
    top: 35%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(4) img {
    height: 42px;
    width: 42px;
    border-radius: 50%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(5) {
    left: 15%;
    bottom: 120px
}

.home6-testimonial-area .sm-img-wrap li:nth-child(5) img {
    height: 60px;
    width: 60px;
    border-radius: 50%
}

.home6-testimonial-area .sm-img-wrap li:nth-child(6) {
    right: 15%;
    bottom: 120px
}

.home6-testimonial-area .sm-img-wrap li:nth-child(6) img {
    height: 60px;
    width: 60px;
    border-radius: 50%
}

.home6-testimonial-area .testimonial-wrapper {
    text-align: center
}

.home6-testimonial-area .testimonial-wrapper .testimonial-content {
    padding-top: 35px;
    margin-bottom: 40px
}

.home6-testimonial-area .testimonial-wrapper .testimonial-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 22px;
    line-height: 1.9;
    text-transform: capitalize;
    color: #e4e4e4
}

.home6-testimonial-area .testimonial-wrapper .author-area h4 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 24px;
    color: #e4e4e4;
    margin-bottom: 0
}

.home6-testimonial-area .testimonial-wrapper .author-area span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .02em;
    color: var(--theme-color)
}

.home6-what-we-do-section .mb-120 {
    margin-bottom: 120px
}

@media(max-width:991px) {
    .home6-what-we-do-section .mb-120 {
        margin-bottom: 80px
    }
}

.home6-what-we-do-section .single-process {
    transition: .5s
}

.home6-what-we-do-section .single-process .icon {
    margin-bottom: 25px
}

.home6-what-we-do-section .single-process .icon svg {
    fill: var(--theme-color)
}

.home6-what-we-do-section .single-process .content h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 10px;
    transition: .5s
}

@media(max-width:1199px) {
    .home6-what-we-do-section .single-process .content h3 {
        font-size: 28px
    }
}

.home6-what-we-do-section .single-process .content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.home6-what-we-do-section .single-process:hover .sl h2 {
    -webkit-text-stroke: 1px rgba(6, 216, 137, .2)
}

.home6-what-we-do-section .single-process:hover .content h3 {
    color: var(--theme-color)
}

.home6-what-we-do-section .what-we-do-content h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 45px;
    line-height: 1.3;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 15px
}

@media(max-width:767px) {
    .home6-what-we-do-section .what-we-do-content h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .home6-what-we-do-section .what-we-do-content h2 {
        font-size: 30px
    }
}

.home6-what-we-do-section .what-we-do-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.home6-what-we-do-section .what-we-do-content .about-featue {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 2;
    columns: 2;
    padding-top: 35px
}

@media(max-width:576px) {
    .home6-what-we-do-section .what-we-do-content .about-featue {
        -moz-columns: 1;
        columns: 1
    }
}

.home6-what-we-do-section .what-we-do-content .about-featue li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    color: #d7d7d7;
    margin-bottom: 30px
}

.home6-what-we-do-section .what-we-do-content .about-featue li:nth-child(even) {
    margin-bottom: 0
}

@media(max-width:576px) {
    .home6-what-we-do-section .what-we-do-content .about-featue li:nth-child(even) {
        margin-bottom: 30px
    }
}

@media(max-width:576px) {
    .home6-what-we-do-section .what-we-do-content .about-featue li:last-child {
        margin-bottom: 0
    }
}

.home6-what-we-do-section .what-we-do-content .about-featue li svg {
    fill: var(--theme-color)
}

.mb-20 {
    margin-bottom: 20px
}

.home6-contact-section .contact-content>p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.home6-contact-section .contact-content .contact-number {
    border: 1px solid var(--theme-color);
    padding: 10px 35px;
    display: inline-block;
    margin-top: 90px;
    margin-bottom: 70px
}

@media(max-width:576px) {
    .home6-contact-section .contact-content .contact-number {
        padding: 10px;
        margin-top: 50px;
        margin-bottom: 40px
    }
}

.home6-contact-section .contact-content .contact-number p {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 25px;
    letter-spacing: .01em;
    color: var(--theme-color)
}

@media(max-width:576px) {
    .home6-contact-section .contact-content .contact-number p {
        font-size: 16px
    }
}

.home6-contact-section .contact-content .contact-number p a {
    color: var(--white-color);
    transition: .5s
}

.home6-contact-section .contact-content .contact-number p a:hover {
    color: rgba(6, 216, 137, .5)
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, .2);
    border-radius: 0
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button {
    background-color: transparent;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--white-color);
    padding: 25px 20px 20px
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: var(--theme-color);
    border-bottom: 1px solid rgba(238, 238, 238, .2)
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button:not(.collapsed):focus {
    box-shadow: none;
    border: none
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: bootstrap-icons !important;
    content: "\f2ea";
    color: var(--title-color)
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    border: none
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    content: "\f4fe";
    font-family: bootstrap-icons !important;
    color: rgba(255, 255, 255, .5);
    background-image: none;
    background-size: contain;
    transition: transform .2s ease-in-out
}

.home6-contact-section .contact-content .contact-step .accordion .accordion-item .accordion-body {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding: 20px 20px 30px
}

.home6-contact-section .contact-form-wrap {
    background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px
}

@media(min-width:992px) {
    .home6-contact-section .contact-form-wrap {
        margin-left: 25px
    }
}

.home6-contact-section .contact-form-wrap .form-tltle {
    padding: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.home6-contact-section .contact-form-wrap .form-tltle h5 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 26px;
    color: var(--white-color);
    margin-bottom: 0;
    text-align: center
}

.home6-contact-section .contact-form-wrap .contact-form {
    padding: 40px 45px
}

@media(max-width:1399px) {
    .home6-contact-section .contact-form-wrap .contact-form {
        padding: 40px 25px
    }
}

.home6-contact-section .contact-form-wrap .contact-form form label {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 8px
}

.home6-contact-section .contact-form-wrap .contact-form form input,
.home6-contact-section .contact-form-wrap .contact-form form textarea {
    width: 100%;
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    height: 41px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 15px
}

.home6-contact-section .contact-form-wrap .contact-form form textarea {
    height: 140px
}

.home6-contact-section .contact-form-wrap .contact-form form .primary-btn3 {
    font-size: 17px;
    font-weight: 700;
    padding: 12px 62px;
    border: none;
    margin-top: 20px
}

.breadcrumbs {
    position: relative;
    background-image: url(../img/inner-pages/inner-page-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1
}

.breadcrumbs .inner-banner-1 {
    border-radius: 130px;
    position: absolute;
    top: 115px;
    left: 45px
}

@media(max-width:991px) {
    .breadcrumbs .inner-banner-1 {
        display: none;
        visibility: hidden
    }
}

.breadcrumbs .inner-banner-1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 130px;
    background: rgba(29, 29, 29, .6)
}

.breadcrumbs .inner-banner-1 img {
    border-radius: 130px
}

.breadcrumbs .inner-banner-2 {
    position: absolute;
    bottom: 0;
    right: 170px;
    border-radius: 130px 130px 0 0
}

@media(max-width:991px) {
    .breadcrumbs .inner-banner-2 {
        display: none;
        visibility: hidden
    }
}

.breadcrumbs .inner-banner-2::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 130px 130px 0 0;
    background: rgba(29, 29, 29, .6)
}

.breadcrumbs .inner-banner-2 img {
    border-radius: 130px 130px 0 0
}

.breadcrumbs .breadcrumb-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 205px 0 140px
}

@media(max-width:576px) {
    .breadcrumbs .breadcrumb-wrapper {
        padding: 155px 0 90px
    }
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt {
    position: relative;
    text-align: center
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: #b5b5b5;
    display: inline-block;
    padding: 8px 40px;
    position: relative;
    background-color: rgba(6, 216, 137, .2);
    margin-bottom: 10px
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span::after {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--white-color);
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt>span::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--white-color);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%)
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt h1 {
    font-size: 54px;
    font-weight: 700;
    color: var(--white-color);
    text-transform: capitalize;
    margin-bottom: 15px
}

@media(max-width:576px) {
    .breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt h1 {
        font-size: 40px
    }
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list {
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list a {
    color: var(--theme-color)
}

.breadcrumbs .breadcrumb-wrapper .breadcrumb-cnt .breadcrumb-list i {
    margin: 0 5px
}

.pagination {
    padding-top: 60px;
    gap: 20px
}

.pagination .page-item.disabled .page-link {
    background-color: transparent;
    background-color: transparent;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    font-family: var(--font-saira)
}

.pagination li {
    padding: 0
}

.pagination li a {
    padding: 0;
    height: 34px;
    width: 34px;
    background-color: transparent;
    border: 1px solid var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    color: var(--theme-color);
    font-family: var(--font-saira);
    transition: .5s
}

.pagination li a.active {
    color: var(--black-color2);
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.pagination li a:hover {
    color: var(--white-color);
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.error-page-bg {
    background-image: url(../img/inner-pages/error-bg.png), linear-gradient(#1d1d1d, #1d1d1d);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 150px 0
}

@media(min-width:1400px) and (max-width:1599px) {
    .error-page-bg {
        padding: 75px 0
    }
}

@media(max-width:1399px) {
    .error-page-bg {
        padding: 70px 0
    }
}

.error-page-bg .error-content h1 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 80px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--white-color);
    margin-bottom: 20px
}

@media(max-width:576px) {
    .error-page-bg .error-content h1 {
        font-size: 60px
    }
}

.error-page-bg .error-content h1 span {
    font-weight: 300
}

.error-page-bg .error-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 35px;
    align-items: center;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 50px
}

.error-page-bg .primary-btn3 {
    padding: 14px 33px
}

.error-page-bg .primary-btn3 svg {
    vertical-align: baseline;
    transition: .5s;
    fill: var(--black-color2)
}

.error-page-bg .primary-btn3:hover svg {
    fill: var(--white-color)
}

.error-page-bg .error-img {
    display: flex;
    justify-content: end
}

@media(max-width:991px) {
    .error-page-bg .error-img {
        display: none;
        visibility: hidden
    }
}

@media(max-width:991px) {
    .case-study-pages .case-study-wrap .case-img {
        margin-bottom: 40px
    }
}

.case-study-pages .case-study-wrap .case-content {
    padding-left: 15px
}

@media(max-width:991px) {
    .case-study-pages .case-study-wrap .case-content {
        padding-left: 0
    }
}

.case-study-pages .case-study-wrap .case-content>span {
    font-family: var(--theme-color);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 8px
}

.case-study-pages .case-study-wrap .case-content h3 {
    margin-bottom: 15px
}

.case-study-pages .case-study-wrap .case-content h3 a {
    font-family: var(--theme-color);
    font-weight: 600;
    font-size: 30px;
    line-height: 1.4;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s
}

@media(max-width:576px) {
    .case-study-pages .case-study-wrap .case-content h3 a {
        font-size: 24px
    }
}

.case-study-pages .case-study-wrap .case-content h3 a:hover {
    color: var(--theme-color)
}

.case-study-pages .case-study-wrap .case-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 23px
}

.case-study-pages .primary-btn3 {
    padding: 14px 50px
}

.blog-banner .blog-banner-wrap {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 30px
}

.blog-banner .blog-banner-wrap .banner-img {
    border-radius: 30px;
    position: relative;
    width: 100%
}

.blog-banner .blog-banner-wrap .banner-img::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #171717 8.12%, #171717 40.79%, rgba(23, 23, 23, 0.96) 49.45%, rgba(23, 23, 23, 0.94) 55.48%, rgba(23, 23, 23, 0.1) 99.09%);
    border-radius: 30px
}

.blog-banner .blog-banner-wrap .banner-img img {
    width: 100%;
    border-radius: 30px;
    min-height: 400px;
    -o-object-fit: cover;
    object-fit: cover
}

.blog-banner .blog-banner-wrap .banner-content {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 550px;
    width: 100%;
    padding: 120px 35px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media(max-width:576px) {
    .blog-banner .blog-banner-wrap .banner-content {
        padding: 70px 20px
    }
}

.blog-banner .blog-banner-wrap .banner-content h2 {
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 60px;
    letter-spacing: .01em;
    color: var(--white-color);
    margin-bottom: 10px
}

.blog-banner .blog-banner-wrap .banner-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 35px
}

.blog-banner .blog-banner-wrap .banner-content .form-inner {
    display: flex;
    align-items: center
}

@media(max-width:576px) {
    .blog-banner .blog-banner-wrap .banner-content .form-inner {
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px
    }
}

.blog-banner .blog-banner-wrap .banner-content .form-inner input {
    width: 100%;
    background: rgba(27, 31, 35, .8);
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 165px 0 0 165px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 13px;
    color: #a4a4a4;
    height: 55px;
    padding: 25px 20px
}

@media(max-width:576px) {
    .blog-banner .blog-banner-wrap .banner-content .form-inner input {
        border-radius: 10px;
        height: 48px
    }
}

.blog-banner .blog-banner-wrap .banner-content .form-inner button {
    height: 55px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: var(--theme-color);
    border-radius: 0 165px 165px 0;
    padding: 10px 30px;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 17px;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: #1d1d1d
}

.blog-banner .blog-banner-wrap .banner-content .form-inner button svg {
    stroke: #1d1d1d
}

@media(max-width:576px) {
    .blog-banner .blog-banner-wrap .banner-content .form-inner button {
        border-radius: 10px;
        height: 48px;
        padding: 10px 25px
    }
}

.portfolio-masonary-page .isotope-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    background: rgba(255, 255, 255, .05);
    border-radius: 80px;
    max-width: 750px;
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

@media(max-width:767px) {
    .portfolio-masonary-page .isotope-menu {
        flex-wrap: wrap;
        border-radius: 10px;
        justify-content: center
    }
}

.portfolio-masonary-page .isotope-menu li {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #e4e4e4;
    padding: 10px 35px;
    border-radius: 50px;
    cursor: pointer

}

@media(max-width:991px) {
    .portfolio-masonary-page .isotope-menu li {
        padding: 10px 30px
    }
}

@media(max-width:767px) {
    .portfolio-masonary-page .isotope-menu li {
        border-radius: 10px
    }
}

.portfolio-masonary-page .isotope-menu li.active {
    background: var(--theme-color);
    box-shadow: inset 0 0 10px rgba(6, 6, 6, .15)
}

.portfolio-masonary-page .single-work {
    position: relative;
    border-radius: 5px;
    transition: .5s
}

.portfolio-masonary-page .single-work .work-img {
    border-radius: 5px
}

.portfolio-masonary-page .single-work .work-img img {
    border-radius: 5px
}

@media(max-width:576px) {
    .portfolio-masonary-page .single-work .work-img img {
        width: 100%
    }
}

.portfolio-masonary-page .single-work .work-content {
    background: linear-gradient(180deg, rgba(29, 29, 29, 0) 23.76%, #1D1D1D 90.19%);
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 25px;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: .5s;
    opacity: 0
}

@media(max-width:1199px) {
    .portfolio-masonary-page .single-work .work-content {
        padding: 25px
    }
}

.portfolio-masonary-page .single-work .work-content h3 {
    margin-bottom: 5px;
    line-height: 1.1
}

.portfolio-masonary-page .single-work .work-content h3 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .5s;
    line-height: 1.1
}

@media(max-width:1199px) {
    .portfolio-masonary-page .single-work .work-content h3 a {
        font-size: 24px
    }
}

.portfolio-masonary-page .single-work .work-content h3 a:hover {
    color: var(--theme-color)
}

.portfolio-masonary-page .single-work .work-content span {
    font-family: var(--font-saira);
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    opacity: .5
}

.portfolio-masonary-page .single-work:hover .work-content {
    opacity: 1;
    transform: scaleY(1)
}

.portfolio-masonary-page .load-more-btn a {
    padding: 14px 50px
}

.portfolio-details .portfolio-img {
    border-radius: 10px;
    height: 100%
}

.portfolio-details .portfolio-img img {
    border-radius: 10px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.portfolio-details .portfolio-content h3 {
    font-family: var(--font-saira);
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 10px
}

@media(max-width:767px) {
    .portfolio-details .portfolio-content h3 {
        font-size: 30px
    }
}

.portfolio-details .portfolio-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 15px
}

.portfolio-details .portfolio-content p:last-child {
    margin-bottom: 0
}

.portfolio-details .portfolio-content .working-process {
    padding-top: 20px;
    margin-bottom: 70px
}

.portfolio-details .portfolio-content .working-process h3 {
    margin-bottom: 25px
}

.portfolio-details .portfolio-content .working-process .single-process {
    background: rgba(217, 217, 217, .05);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    padding: 35px 20px;
    position: relative
}

.portfolio-details .portfolio-content .working-process .single-process .icon {
    position: absolute;
    right: 10px;
    top: 10px
}

.portfolio-details .portfolio-content .working-process .single-process span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 10px
}

.portfolio-details .portfolio-content .working-process .single-process h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 28px;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:767px) {
    .portfolio-details .portfolio-content .working-process .single-process h3 {
        font-size: 24px
    }
}

.portfolio-details .portfolio-content .working-process .single-process p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.portfolio-details .portfolio-info {
    background-image: url(../img/inner-pages/portfolio-info-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-bottom: 50px
}

.portfolio-details .portfolio-info ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 15px 0
}

.portfolio-details .portfolio-info ul li {
    text-align: center;
    padding: 25px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, .04)
}

.portfolio-details .portfolio-info ul li:last-child {
    border-bottom: unset
}

.portfolio-details .portfolio-info ul li span {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    color: #b5b5b5;
    display: inline-block;
    margin-bottom: 5px
}

.portfolio-details .portfolio-info ul li h5 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    color: var(--white-color)
}

.portfolio-details .portfolio-details-sm-banner {
    background-image: url(../img/inner-pages/portfolio-dt-06.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 30px 240px;
    border-radius: 20px
}

.portfolio-details .portfolio-details-sm-banner .section-title-5 h2 {
    font-size: 40px;
    margin-bottom: 30px
}

.portfolio-details .portfolio-details-sm-banner .section-title-5 a {
    padding: 14px 40px
}

.portfolio-details .magnetic-wrap {
    height: 100%
}

.details-navigation {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding: 30px 0;
    gap: 30px
}

@media(max-width:991px) {
    .details-navigation {
        flex-wrap: wrap;
        justify-content: center
    }
}

.details-navigation .single-navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    transition: .5s;
    max-width: 425px;
    width: 100%
}

.details-navigation .single-navigation .content {
    text-align: end
}

.details-navigation .single-navigation .content a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
    color: var(--theme-color)
}

.details-navigation .single-navigation .content h4 {
    margin-bottom: 0
}

.details-navigation .single-navigation .content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:767px) {
    .details-navigation .single-navigation .content h4 a {
        font-size: 18px
    }
}

.details-navigation .single-navigation .img {
    height: 80px;
    min-width: 80px;
    border-radius: 50%;
    position: relative;
    transition: .5s
}

.details-navigation .single-navigation .img::after {
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: rgba(29, 29, 29, .7);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transform: scale(.4);
    transition: .5s
}

.details-navigation .single-navigation .img img {
    height: 80px;
    width: 80px;
    border-radius: 50%
}

.details-navigation .single-navigation .img .arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 52px;
    width: 52px;
    border-radius: 50%;
    border: 1px solid var(--white-color);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s
}

.details-navigation .single-navigation .img .arrow svg {
    stroke: var(--white-color)
}

.details-navigation .single-navigation.two .content {
    text-align: start
}

.details-navigation .single-navigation:hover .img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    position: relative;
    transition: .5s
}

.details-navigation .single-navigation:hover .img::after {
    opacity: 1;
    transform: scale(1)
}

.details-navigation .single-navigation:hover .img .arrow {
    opacity: 1;
    transform: translate(-50%, -50%)
}

.service-details .about-content .section-title-5 {
    margin-bottom: 20px
}

.service-details .about-content .section-title-5 h2 {
    font-size: 55px
}

@media(max-width:1199px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 40px
    }
}

@media(max-width:767px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .service-details .about-content .section-title-5 h2 {
        font-size: 30px
    }
}

.service-details .about-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.service-details .about-content .about-featue {
    margin: 0;
    padding: 0;
    list-style: none;
    -moz-columns: 2;
    columns: 2
}

@media(max-width:576px) {
    .service-details .about-content .about-featue {
        -moz-columns: 1;
        columns: 1
    }
}

.service-details .about-content .about-featue li {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    letter-spacing: .02em;
    color: #d7d7d7;
    padding-top: 30px
}

.service-details .about-content .about-featue li svg {
    fill: var(--theme-color)
}

.service-details .service-img img {
    border-radius: 5px
}

.service-details .how-we-work-section .section-title h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 40px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 0
}

.service-details .how-we-work-section .work-item {
    position: relative;
    padding: 0 100px;
    z-index: 1
}

@media(max-width:1500px) {
    .service-details .how-we-work-section .work-item {
        padding: 0 30px
    }
}

@media(max-width:991px) {
    .service-details .how-we-work-section .work-item {
        padding: 0
    }
}

.service-details .how-we-work-section .work-item::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, .1);
    position: absolute;
    left: 0;
    top: 82px;
    z-index: -1
}

@media(max-width:1199px) {
    .service-details .how-we-work-section .work-item::after {
        display: none;
        visibility: hidden
    }
}

.service-details .how-we-work-section .work-item .single-work {
    max-width: 280px;
    width: 100%
}

.service-details .how-we-work-section .work-item .single-work .work-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px
}

.service-details .how-we-work-section .work-item .single-work .work-content h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 8px
}

@media(max-width:991px) {
    .service-details .how-we-work-section .work-item .single-work .work-content h3 {
        font-size: 24px
    }
}

@media(max-width:767px) {
    .service-details .how-we-work-section .work-item .single-work .work-content h3 {
        font-size: 22px
    }
}

.service-details .how-we-work-section .work-item .single-work .work-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.service-details .service-sort-driscription-area .driscription-img {
    margin-bottom: 40px
}

.service-details .service-sort-driscription-area .driscription-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.service-details .service-faq-area .faq-content-area h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 40px;
    line-height: 1.5;
    text-transform: capitalize;
    color: #fff
}

@media(max-width:767px) {
    .service-details .service-faq-area .faq-content-area h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .service-details .service-faq-area .faq-content-area h2 {
        font-size: 30px
    }
}

.service-details .service-faq-area .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, .2);
    border-radius: 0
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button {
    background-color: transparent;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 17px;
    text-transform: capitalize;
    color: #b5b5b5;
    padding: 25px 0 20px
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: var(--theme-color);
    border-bottom: 1px solid rgba(238, 238, 238, .2)
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed):focus {
    box-shadow: none;
    border: none
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: bootstrap-icons !important;
    content: "\f2ea";
    color: var(--title-color)
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    border: none
}

.service-details .service-faq-area .accordion .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    content: "\f4fe";
    font-weight: 600;
    font-family: bootstrap-icons !important;
    color: rgba(255, 255, 255, .5);
    background-image: none;
    background-size: contain;
    transition: transform .2s ease-in-out
}

.service-details .service-faq-area .accordion .accordion-item .accordion-body {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding: 20px 20px 30px
}

.case-study-details .case-study-title {
    max-width: 960px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px
}

.case-study-details .case-study-title h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 50px;
    line-height: 1.4;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 0;
    text-align: center
}

.case-study-details .case-big-img {
    border-radius: 5px;
    margin-bottom: 40px
}

.case-study-details .case-big-img img {
    border-radius: 5px
}

.case-study-details .case-img {
    border-radius: 5px
}

.case-study-details .case-img img {
    border-radius: 5px
}

.case-study-details .case-content h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 40px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 20px
}

@media(max-width:767px) {
    .case-study-details .case-content h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .case-study-details .case-content h2 {
        font-size: 30px
    }
}

.case-study-details .case-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 15px
}

.case-study-details .case-content p:last-child {
    margin-bottom: 0
}

.case-study-details .single-process {
    background: rgba(217, 217, 217, .05);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    padding: 35px 20px;
    position: relative
}

.case-study-details .single-process .icon {
    position: absolute;
    right: 10px;
    top: 10px
}

.case-study-details .single-process span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: var(--theme-color);
    display: inline-block;
    margin-bottom: 10px
}

.case-study-details .single-process h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 28px;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:767px) {
    .case-study-details .single-process h3 {
        font-size: 24px
    }
}

.case-study-details .single-process p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.bolog-details-area .post-thumb {
    margin-bottom: 35px
}

.bolog-details-area .post-thumb img {
    border-radius: 5px
}

.bolog-details-area .blog-details-content>span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: var(--theme-color);
    border: 1px solid rgba(6, 216, 137, .3);
    border-radius: 5px;
    padding: 5px 22px;
    display: inline-block;
    margin-bottom: 25px
}

.bolog-details-area .blog-details-content h2 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 50px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 30px
}

@media(max-width:991px) {
    .bolog-details-area .blog-details-content h2 {
        font-size: 45px
    }
}

@media(max-width:767px) {
    .bolog-details-area .blog-details-content h2 {
        font-size: 40px
    }
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content h2 {
        font-size: 36px
    }
}

.bolog-details-area .blog-details-content .author-and-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, .05);
    border-bottom: 1px solid rgba(255, 255, 255, .05);
    margin-bottom: 35px
}

@media(max-width:991px) {
    .bolog-details-area .blog-details-content .author-and-meta {
        flex-wrap: wrap;
        gap: 25px
    }
}

.bolog-details-area .blog-details-content .author-and-meta .author-area {
    display: flex;
    align-items: center;
    gap: 10px
}

.bolog-details-area .blog-details-content .author-and-meta .author-area .author-img img {
    height: 30px;
    width: 30px;
    border-radius: 50%
}

.bolog-details-area .blog-details-content .author-and-meta .author-area .author-content h6 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 15px;
    text-transform: capitalize;
    color: #b5b5b5
}

.bolog-details-area .blog-details-content .author-and-meta .author-area .author-content h6 span {
    color: var(--theme-color)
}

.bolog-details-area .blog-details-content .author-and-meta .blog-meta {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 40px
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content .author-and-meta .blog-meta {
        gap: 10px;
        flex-wrap: wrap
    }
}

.bolog-details-area .blog-details-content .author-and-meta .blog-meta li {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: #b5b5b5;
    display: flex;
    align-items: center;
    gap: 7px;
    position: relative
}

.bolog-details-area .blog-details-content .author-and-meta .blog-meta li svg {
    width: 13px;
    fill: #b5b5b5
}

.bolog-details-area .blog-details-content .author-and-meta .blog-meta li::after {
    content: "";
    height: 10px;
    width: 2px;
    background-color: var(--theme-color);
    border-radius: 4px;
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translateY(-50%)
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content .author-and-meta .blog-meta li::after {
        display: none;
        visibility: hidden
    }
}

.bolog-details-area .blog-details-content .author-and-meta .blog-meta li:first-child::after {
    display: none;
    visibility: hidden
}

.bolog-details-area .blog-details-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 32px;
    color: #b5b5b5;
    margin-bottom: 20px
}

.bolog-details-area .blog-details-content p:last-child {
    margin-bottom: 0
}

.bolog-details-area .blog-details-content>h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 30px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 20px
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content>h3 {
        font-size: 24px;
        margin-bottom: 15px
    }
}

.bolog-details-area .blog-details-content blockquote {
    background-image: url(../img/inner-pages/blockquote-bg.png), linear-gradient(#171717, #171717);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 80px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, .05);
    text-align: center;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px
}

@media(max-width:1199px) {
    .bolog-details-area .blog-details-content blockquote {
        padding: 30px
    }
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content blockquote {
        padding: 30px 20px
    }
}

.bolog-details-area .blog-details-content blockquote .blockquote-icon-01 {
    position: absolute;
    left: 60px;
    bottom: 20px
}

@media(max-width:767px) {
    .bolog-details-area .blog-details-content blockquote .blockquote-icon-01 {
        left: 20px
    }
}

.bolog-details-area .blog-details-content blockquote .blockquote-icon-02 {
    position: absolute;
    right: 60px;
    bottom: 20px
}

@media(max-width:767px) {
    .bolog-details-area .blog-details-content blockquote .blockquote-icon-02 {
        right: 20px
    }
}

.bolog-details-area .blog-details-content blockquote p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: .03em;
    color: var(--white-color)
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content blockquote p {
        font-size: 18px;
        line-height: 40px
    }
}

.bolog-details-area .blog-details-content blockquote h3 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 30px;
    color: var(--theme-color)
}

@media(max-width:576px) {
    .bolog-details-area .blog-details-content blockquote h3 {
        font-size: 24px
    }
}

.bolog-details-area .blog-details-content .blog-details-img-group {
    padding-top: 15px;
    margin-bottom: 35px
}

.bolog-details-area .blog-details-content .blog-details-img img {
    border-radius: 5px
}

.bolog-details-area .blog-details-content .blog-tag-and-social {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

@media(max-width:1199px) {
    .bolog-details-area .blog-details-content .blog-tag-and-social {
        flex-wrap: wrap;
        gap: 30px
    }
}

.bolog-details-area .blog-details-content .blog-tag-and-social .tag {
    display: flex;
    align-items: center;
    gap: 15px
}

.bolog-details-area .blog-details-content .blog-tag-and-social .tag h6 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #fff
}

.bolog-details-area .blog-details-content .blog-tag-and-social .tag ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px
}

@media(max-width:767px) {
    .bolog-details-area .blog-details-content .blog-tag-and-social .tag ul {
        flex-wrap: wrap
    }
}

.bolog-details-area .blog-details-content .blog-tag-and-social .tag ul li a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .1);
    padding: 3px 15px;
    display: inline-block;
    transition: .5s
}

.bolog-details-area .blog-details-content .blog-tag-and-social .tag ul li a:hover {
    color: var(--theme-color);
    border: 1px solid rgba(6, 216, 137, .1)
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social {
    display: flex;
    align-items: end;
    gap: 15px
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social h6 {
    margin-bottom: 0;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #fff
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 15px
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social ul li {
    line-height: 1
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social ul li a {
    color: rgba(255, 255, 255, .2);
    font-size: 18px;
    transition: .5s;
    line-height: .5
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social ul li a i {
    line-height: 1
}

.bolog-details-area .blog-details-content .blog-tag-and-social .social ul li a:hover {
    color: var(--theme-color)
}

.bolog-details-area .details-navigation {
    margin-top: 40px
}

.widget-area .single-widgets {
    background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgba(255, 255, 255, .1);
    padding: 35px 25px;
    margin-bottom: 35px;
    border-radius: 5px
}

.widget-area .single-widgets:last-child {
    margin-bottom: 0
}

.widget-area .single-widgets .widget-title {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 25px
}

.widget-area .single-widgets .widget-title h4 {
    font-weight: 500;
    font-size: 24px;
    display: inline-block;
    text-transform: capitalize;
    color: var(--theme-color);
    padding-bottom: 5px
}

.widget-area .wp-block-search__inside-wrapper {
    display: flex;
    align-items: center
}

.widget-area .wp-block-search__inside-wrapper input {
    width: 100%;
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px 0 0 5px;
    height: 50px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 15px
}

.widget-area .wp-block-search__inside-wrapper .wp-block-search__button {
    height: 50px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: var(--theme-color);
    border-radius: 0 5px 5px 0;
    transition: .5s
}

.widget-area .wp-block-search__inside-wrapper .wp-block-search__button svg {
    fill: var(--black-color2);
    transition: .5s
}

.widget-area .wp-block-search__inside-wrapper .wp-block-search__button:hover svg {
    fill: var(--white-color)
}

.widget-area .wp-block-categoris-cloud {
    margin: 0;
    padding: 0;
    list-style: none
}

.widget-area .wp-block-categoris-cloud li {
    margin-bottom: 15px
}

.widget-area .wp-block-categoris-cloud li:last-child {
    margin-bottom: 0
}

.widget-area .wp-block-categoris-cloud li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    color: #b5b5b5;
    transition: .5s
}

.widget-area .wp-block-categoris-cloud li a:hover {
    color: var(--theme-color)
}

.widget-area .recent-post-wraper .widget-cnt {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px
}

.widget-area .recent-post-wraper .widget-cnt:last-child {
    margin-bottom: 0
}

.widget-area .recent-post-wraper .widget-cnt .wi {
    height: 85px;
    min-width: 110px
}

.widget-area .recent-post-wraper .widget-cnt .wi img {
    border-radius: 5px;
    height: 85px;
    width: 110px
}

.widget-area .recent-post-wraper .widget-cnt .wc a {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-saira);
    color: #b5b5b5;
    transition: .5s;
    display: inline-block
}

@media(max-width:576px) {
    .widget-area .recent-post-wraper .widget-cnt .wc a {
        font-weight: 400
    }
}

.widget-area .recent-post-wraper .widget-cnt .wc a:hover {
    color: var(--theme-color)
}

.widget-area .recent-post-wraper .widget-cnt .wc h6 {
    margin-bottom: 5px
}

.widget-area .recent-post-wraper .widget-cnt .wc h6 a {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-saira);
    color: var(--white-color);
    transition: .5s
}

@media(max-width:576px) {
    .widget-area .recent-post-wraper .widget-cnt .wc h6 a {
        font-size: 16px
    }
}

.widget-area .recent-post-wraper .widget-cnt .wc h6 a:hover {
    color: var(--theme-color)
}

.widget-area .wp-block-tag-cloud {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap
}

.widget-area .wp-block-tag-cloud a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .1);
    padding: 4px 12px;
    display: inline-block;
    transition: .5s
}

.widget-area .wp-block-tag-cloud a:hover {
    color: var(--theme-color);
    border: 1px solid rgba(6, 216, 137, .1)
}

.comments-area h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 35px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 0;
    border-bottom: 2px solid rgba(6, 216, 137, .4);
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 50px
}

.comments-area .single-comment {
    display: flex;
    gap: 30px;
    margin-bottom: 60px
}

.comments-area .single-comment:last-child {
    margin-bottom: 0
}

@media(max-width:576px) {
    .comments-area .single-comment {
        flex-wrap: wrap
    }
}

.comments-area .single-comment .author-thumb {
    min-width: 120px;
    height: 120px
}

.comments-area .single-comment .author-thumb img {
    border-radius: 10px;
    width: 120px;
    height: 120px
}

.comments-area .single-comment .comment-content .author-post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px
}

@media(max-width:767px) {
    .comments-area .single-comment .comment-content .author-post {
        flex-wrap: wrap;
        gap: 15px
    }
}

.comments-area .single-comment .comment-content .author-post .author-info h4 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 5px
}

.comments-area .single-comment .comment-content .author-post .author-info span {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: var(--theme-color)
}

.comments-area .single-comment .comment-content .author-post .reply a {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .03em;
    color: #fff;
    border: 1px solid rgba(6, 216, 137, .3);
    border-radius: 152px;
    padding: 7px 30px;
    display: flex;
    gap: 8px;
    transition: .5s
}

.comments-area .single-comment .comment-content .author-post .reply a:hover {
    color: var(--theme-color);
    border-color: var(--theme-color)
}

.comments-area .single-comment .comment-content p {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .03em;
    color: #b5b5b5
}

.comment-form h3 {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 35px;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 0;
    border-bottom: 2px solid rgba(6, 216, 137, .4);
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 50px
}

.comment-form input {
    width: 100%;
    background: #0d0d0f;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 5px;
    height: 58px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 25px
}

.comment-form textarea {
    width: 100%;
    background: #0d0d0f;
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 5px;
    min-height: 150px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 15px
}

.comment-form .primary-btn3 {
    border: none;
    outline: none;
    padding: 14px 41px
}

.blog-masonary-area .divider {
    position: relative
}

.blog-masonary-area .divider::after {
    content: "";
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, .05);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1
}

@media(max-width:767px) {
    .blog-masonary-area .divider::after {
        display: none;
        visibility: hidden
    }
}

.blog-masonary-area .col-lg-5:nth-child(even) {
    padding-top: 70px
}

@media(max-width:767px) {
    .blog-masonary-area .col-lg-5:nth-child(even) {
        padding-top: 0
    }
}

.blog-masonary-area .single-blog {
    border-radius: 5px;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-img {
    position: relative;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-img img {
    border-radius: 5px 5px 0 0;
    transition: .5s;
    width: 100%
}

.blog-masonary-area .single-blog .blog-img .blog-tag {
    position: absolute;
    top: 0;
    right: 0
}

.blog-masonary-area .single-blog .blog-img .blog-tag a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    color: var(--black-color2);
    background: var(--theme-color);
    border-radius: 0 5px 0 0;
    padding: 10px 16px;
    display: inline-block;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.blog-masonary-area .single-blog .blog-img .blog-tag a::after {
    position: absolute;
    content: "";
    display: block;
    left: 15%;
    right: -20%;
    top: -4%;
    height: 150%;
    width: 150%;
    bottom: 0;
    border-radius: 0 2px 0 0;
    background-color: #0a1019;
    transform: skewX(45deg) scale(0, 1);
    z-index: -1;
    transition: all .5s ease-out 0s
}

.blog-masonary-area .single-blog .blog-img .blog-tag a:hover {
    color: var(--white-color)
}

.blog-masonary-area .single-blog .blog-img .blog-tag a:hover::after {
    transform: skewX(45deg) scale(1, 1)
}

.blog-masonary-area .single-blog .blog-content {
    background: #151515;
    padding: 20px 25px;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-meta {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px
}

.blog-masonary-area .single-blog .blog-content .blog-meta li a {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    color: #b5b5b5;
    transition: .4s
}

.blog-masonary-area .single-blog .blog-content .blog-meta li a:hover {
    color: var(--theme-color)
}

.blog-masonary-area .single-blog .blog-content h4 {
    margin-bottom: 0
}

.blog-masonary-area .single-blog .blog-content h4 a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 25px;
    line-height: 1.5;
    text-transform: capitalize;
    color: var(--white-color);
    transition: .4s
}

@media(max-width:1199px) {
    .blog-masonary-area .single-blog .blog-content h4 a {
        font-size: 22px
    }
}

.blog-masonary-area .single-blog .blog-content h4 a:hover {
    color: var(--theme-color)
}

.blog-masonary-area .single-blog .blog-content .blog-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 10px
}

.blog-masonary-area .single-blog .blog-content .blog-footer .read-btn a {
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: flex;
    align-items: center;
    gap: 6px;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-footer .read-btn a svg {
    stroke: var(--theme-color);
    transform: rotate(45deg);
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-footer .read-btn a:hover {
    color: var(--white-color)
}

.blog-masonary-area .single-blog .blog-content .blog-footer .read-btn a:hover svg {
    stroke: var(--white-color);
    transform: rotate(90deg)
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area {
    display: flex;
    align-items: center;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area span {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 14px;
    padding: 4px 12px;
    margin-right: -28px;
    transform: scaleX(0);
    transition: .5s;
    transform-origin: right
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul li {
    position: relative
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul li a {
    color: var(--white-color);
    font-size: 18px;
    display: flex;
    transition: .5s
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul li a:hover {
    color: var(--theme-color)
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul li::after {
    content: "";
    height: 2.5px;
    width: 2.5px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    background-color: rgba(255, 255, 255, .2)
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area ul li:first-child::after {
    display: none;
    visibility: hidden
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area:hover span {
    opacity: 0
}

.blog-masonary-area .single-blog .blog-content .blog-footer .social-area:hover ul {
    transform: scaleX(1);
    position: relative;
    z-index: 9
}

.blog-masonary-area .single-blog:hover .blog-img img {
    transform: scale(1.05)
}

.blog-masonary-area .single-blog:hover .blog-content {
    background: #292929
}

.contact-page-wrap .contact-content>span {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: var(--theme-color);
    display: inline-block
}

.contact-page-wrap .contact-content h2 {
    margin-bottom: 5px;
    font-family: var(--font-saira);
    font-weight: 700;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: .03em;
    text-transform: capitalize;
    color: var(--white-color)
}

@media(max-width:767px) {
    .contact-page-wrap .contact-content h2 {
        font-size: 38px
    }
}

@media(max-width:576px) {
    .contact-page-wrap .contact-content h2 {
        font-size: 30px
    }
}

.contact-page-wrap .contact-content p {
    font-family: var(--font-saira);
    font-weight: 300;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    margin-bottom: 0
}

.contact-page-wrap .contact-content .informations {
    padding-top: 50px
}

.contact-page-wrap .contact-content .informations .single-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 15px
}

.contact-page-wrap .contact-content .informations .single-info .icon {
    height: 2.8rem;
    min-width: 2.8rem;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center
}

.contact-page-wrap .contact-content .informations .single-info .icon i {
    font-size: 20px
}

.contact-page-wrap .contact-content .informations .single-info .info a {
    font-size: 16px;
    font-weight: 400;
    color: #b4b4b4;
    display: block;
    transition: all .5s ease
}

.contact-page-wrap .contact-content .informations .single-info .info a:hover {
    color: var(--theme-color)
}

.contact-page-wrap .contact-content .informations .single-info .info p {
    font-size: 16px;
    font-weight: 400;
    color: #b5b5b5;
    display: block;
    transition: all .5s ease
}

.contact-page-wrap .contact-content .follow-area {
    padding: 30px 0
}

.contact-page-wrap .contact-content .follow-area h5 {
    font-size: 22px;
    font-weight: 700;
    color: var(--white-color);
    position: relative;
    margin-bottom: 0
}

.contact-page-wrap .contact-content .follow-area .para {
    font-size: 16px;
    font-weight: 400;
    color: #b5b5b5;
    font-family: poppins, sans-serif;
    line-height: 28px;
    margin-bottom: 10px
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list {
    margin: 0;
    padding: 10px 0 0
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list li a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background: 0 0;
    border-radius: 3px;
    font-size: 18px;
    color: var(--white-color);
    transition: all .35s ease-in-out
}

.contact-page-wrap .contact-content .follow-area .blog-widget-body .follow-list li a:hover {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.contact-page-wrap .contact-form-wrap {
    background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px
}

@media(min-width:992px) {
    .contact-page-wrap .contact-form-wrap {
        margin-left: 25px
    }
}

.contact-page-wrap .contact-form-wrap .form-tltle {
    padding: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.contact-page-wrap .contact-form-wrap .form-tltle h5 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 22px;
    color: var(--white-color);
    margin-bottom: 0;
    text-align: center
}

.contact-page-wrap .contact-form-wrap .contact-form {
    padding: 40px 45px
}

@media(max-width:1399px) {
    .contact-page-wrap .contact-form-wrap .contact-form {
        padding: 40px 25px
    }
}

.contact-page-wrap .contact-form-wrap .contact-form form label {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 8px
}

.contact-page-wrap .contact-form-wrap .contact-form form input,
.contact-page-wrap .contact-form-wrap .contact-form form textarea {
    width: 100%;
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    height: 41px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 15px
}

.contact-page-wrap .contact-form-wrap .contact-form form textarea {
    height: 140px
}

.contact-page-wrap .contact-form-wrap .contact-form form .primary-btn3 {
    font-size: 17px;
    font-weight: 700;
    padding: 12px 62px;
    border: none;
    margin-top: 20px
}

.contact-map iframe {
    width: 100%;
    height: 450px;
    filter: invert(1)
}

.faq-page .faq-items ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    flex-direction: column
}

.faq-page .faq-items ul li {
    margin-bottom: 15px
}

.faq-page .faq-items ul li .nav-link {
    border-radius: 0;
    border: none;
    display: block;
    width: 100%;
    text-align: start;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-saira);
    color: var(--white-color);
    background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    text-align: center
}

.faq-page .faq-items ul li .nav-link.active {
    color: var(--theme-color)
}

.faq-page .accordion .accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(238, 238, 238, .2);
    border-radius: 0
}

.faq-page .accordion .accordion-item .accordion-button {
    background-color: transparent;
    font-family: var(--font-saira);
    font-weight: 600;
    font-size: 22px;
    text-transform: capitalize;
    color: #fff;
    padding: 25px 0 20px
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed) {
    color: var(--theme-color);
    border-bottom: 1px solid rgba(238, 238, 238, .2)
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed):focus {
    box-shadow: none;
    border: none
}

.faq-page .accordion .accordion-item .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: bootstrap-icons !important;
    content: "\f2ea";
    color: var(--title-color)
}

.faq-page .accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
    border: none
}

.faq-page .accordion .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    content: "\f4fe";
    font-weight: 600;
    font-family: bootstrap-icons !important;
    color: rgba(255, 255, 255, .5);
    background-image: none;
    background-size: contain;
    transition: transform .2s ease-in-out
}

.faq-page .accordion .accordion-item .accordion-body {
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: .03em;
    color: #b5b5b5;
    padding: 20px 20px 30px
}

.faq-page .contact-form-wrap {
    background-image: url(../img/home-4/pricing-bg-3.png), linear-gradient(#161A1E, #161A1E);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 10px;
    margin-top: 50px
}

.faq-page .contact-form-wrap .form-tltle {
    padding: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.faq-page .contact-form-wrap .form-tltle h5 {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 22px;
    color: var(--white-color);
    margin-bottom: 0;
    text-align: center
}

.faq-page .contact-form-wrap .contact-form {
    padding: 40px 20px
}

.faq-page .contact-form-wrap .contact-form form label {
    font-family: var(--font-saira);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .02em;
    text-transform: capitalize;
    color: var(--white-color);
    margin-bottom: 8px
}

.faq-page .contact-form-wrap .contact-form form input,
.faq-page .contact-form-wrap .contact-form form textarea {
    width: 100%;
    background: rgba(255, 255, 255, .04);
    border: 1px solid rgba(255, 255, 255, .05);
    border-radius: 5px;
    height: 41px;
    font-family: var(--font-saira);
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .02em;
    color: #dedada;
    padding: 10px 15px
}

.faq-page .contact-form-wrap .contact-form form textarea {
    height: 140px
}

.faq-page .contact-form-wrap .contact-form form .primary-btn3 {
    font-size: 17px;
    font-weight: 700;
    padding: 12px 62px;
    border: none;
    margin-top: 20px
}

.fancybox-infobar {
    display: none;
    visibility: hidden
}

.fancybox-navigation {
    display: none;
    visibility: hidden
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}